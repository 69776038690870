import Layout from "../../Components/Layout/Layout";
import { useState, useEffect, useMemo } from "react";
import {
  Box,
  Divider,
  TextField,
  Typography,
  Button,
  Select,
  MenuItem,
  Grid,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
} from "@mui/material";
import { BackIcon } from "../../Common/Icons";
import FormControl from "@mui/material/FormControl";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

import SelectCheckbox from "../../Components/SelectCheckBox/SelectCheckBox";
import hotel1 from "../../assets/Hotel1.png";
import hotel2 from "../../assets/Hotel2.png";
import Card from "../../Components/Card/Card";
import Checkbox from "@mui/material/Checkbox";
import icon from "../../assets/icon.svg";
import addIcon from "../../assets/addIcon.svg";
import cameraIcon from "../../assets/camera.svg";
import img from "../../assets/img1.png";
import deleteIcon from "../../assets/delete.svg";
import greenIcon from "../../assets/greenIcon.svg";
import redIcon from "../../assets/redIcon.svg";
import blueIcon from "../../assets/blueIcon.svg";
import yellowIcon from "../../assets/yellowIcon.svg";
import Switch from "@mui/material/Switch";
import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { Editor } from "react-draft-wysiwyg";
import TagsInputField from "../../Components/TagsInput/TagsInput";
import TagsComponent from "../../Components/TagsComponent/TagsComponent";
import IconSelect from "../../Components/IconSelect/IconSelect";
import ImageSelect from "../../Components/ImageSelect/ImageSelect";
import DropdownTags from "../../Components/DropdownTags/DropdownTags";
import { useHotelConfigurationContext } from "../../library/store/slices/HotelConfigurationSlicer";
import { useHotelDetailsConfigurationContext } from "../../library/store/slices/HotelDetailsConfigurationSlicer";
import { useHotelsDashboardContext } from "../../library/store/slices/HotelsDashboardSlicer";
import useHotelDashboard from "../../library/hooks/UserHotelDashboard/useHotelDashboard";
import useSetting from "../../library/hooks/Setting/setting";
import { useTagContext } from "../../library/store/slices/TagSlicer";
import HotelDashboardService from "../../library/services/API/hotelDashboard";
import { useNavigate } from "react-router-dom";
import i1 from "../../assets/icon/i1.svg";
import i2 from "../../assets/icon/i2.svg";
import i3 from "../../assets/icon/i3.svg";
import i4 from "../../assets/icon/i4.svg";
import i5 from "../../assets/icon/i5.svg";
import i6 from "../../assets/icon/i6.svg";
import i7 from "../../assets/icon/i7.svg";
import { toast } from "react-toastify";
import HotelImages from "../../Components/HotelImages/HotelImages";
import { format } from "date-fns";
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from "rehype-sanitize";


const label = { inputProps: { "aria-label": "Checkbox demo" } };
const label1 = { inputProps: { "aria-label": "Switch demo" } };
const baseURL = "peloppe.it/#hotel/";

export default function HotelDetailsPage() {
  const navigate = useNavigate();

  //sidebar
  const [isSidebarOpened, setIsSidebarOpened] = useState(false);

  const {
    dispatchFetchAllHotels,
    dispatchFetchHotelImages,
    dispatchFetchHotelDetails,
    callUpdateHotelApi,
  } = useHotelDashboard();

  const { dispatchFetchAllTags } = useSetting();

  const { state: hotelImageResponse } = useHotelConfigurationContext();

  const { state: hoteDetailsResponse } = useHotelDetailsConfigurationContext();

  const { state: hotelDataDataExtentions } = useHotelsDashboardContext();

  const { state: tagsResponse } = useTagContext();

  const [voucherHandlingData, setVoucherHandlingData] = useState([]);
  const [hotelDescription, SetHotelDescription] = useState("");
  const [filterTags, SetFilterTags] = useState([]);
  const [filterSelectedTags, SetFilterSelectedTags] = useState([]);
  const [highlightedTags, setHighlightedTags] = useState([]);
  const [structureTags, setStructureTags] = useState([]);
  const [filterResult, SetFilterResult] = useState([]);
  const [structureTagsResult, SetStructureTagsResult] = useState([]);
  const [urlHelperText, setUrlHelperText] = useState("");
  const [urlError, setUrlError] = useState(false);
  const [hotelSEO, setHotelSEO] = useState({
    url: "",
    metaTitle: "",
    metaDescription: "",
    ratingValue: "",
    reviewCount: "",
    priceRange: "",
  });

  const [accountabilityOption, SetAccountabilityOption] = useState([
    {
      title: "Pre Acconto",
      type: "PREACCONTO",
      isActive: false,
      priceType: "",
      price: 0,
      dataBy: "",
      selectedDate: null,
      selectedDays: 0,
    },
    {
      title: "Acconto",
      type: "ACCONTO",
      isActive: false,
      priceType: "",
      price: 0,
      dataBy: "",
      selectedDate: null,
      selectedDays: 0,
    },
    {
      title: "Saldo",
      type: "SALDO",
      isActive: false,
      priceType: "",
      price: 0,
      dataBy: "",
      selectedDate: null,
      selectedDays: 0,
    },
    {
      title: "Sconto Unico",
      type: "SCONTOUNICO",
      isActive: false,
      priceType: "",
      price: 0,
      dataBy: "",
      selectedDate: null,
      selectedDays: 0,
      accountabilityType: "",
    },
  ]);

  const [paymentInfo, SetPaymentInfo] = useState({
    isCarta: false,
    isBankTransfer: false,
    isScalapay: false,
    isKlarna: false,
  });

  const getOptionText = (type) => {
    if (type === "PREACCONTO" || type === "ACCONTO") {
      return "Entro X Giorni dalla Prenotazione";
    } else if (type === "SALDO") {
      return "Entro X Giorni dal Check In";
    } else {
      return "Entro X Giorni";
    }
  };

  const handlePaymentToggle = (key) => {
    SetPaymentInfo((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const filterSearchTags = [];
  const structurSearchTags = [];

  const icons = [
    { iconSrc: i1, value: "i1.svg" },
    { iconSrc: i2, value: "i2.svg" },
    { iconSrc: i3, value: "i3.svg" },
    { iconSrc: i4, value: "i4.svg" },
    { iconSrc: i5, value: "i5.svg" },
    { iconSrc: i6, value: "i6.svg" },
    { iconSrc: i7, value: "i7.svg" },
  ];
  const [roomData, setRoomData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HotelDashboardService.fetchHotelRooms(code);
        setRoomData(response);
      } catch (error) {
        console.error("Error fetching hotel images:", error);
      }
    };

    fetchData();
  }, []);

  useMemo(() => {
    if (tagsResponse != null && tagsResponse.length > 0) {
      tagsResponse.forEach((tag) => {
        if (tag.tagType == "SERVICE") {
          // if (
          //   !structureTags?.some((existingTag) => existingTag.id === tag.id)
          // ) {
          //   setStructureTags((prevTags) => [...prevTags, tag]);
          // }
          SetStructureTagsResult((prevTags) => [...prevTags, tag]);
          structurSearchTags.push(tag);
        } else if (tag.tagType == "HIGHLIGHTED") {
          if (
            !highlightedTags?.some((existingTag) => existingTag.id === tag.id)
          ) {
            console.log(tag);
            setHighlightedTags((prevTags) => [...prevTags, tag]);
          }
        } else if (tag.tagType == "FILTER") {
          SetFilterResult((prevTags) => [...prevTags, tag]);
          filterSearchTags.push(tag);
        }
      });
      const hotelServiceTag = hoteDetailsResponse.serviceTags;
      const newData = [...filterSearchTags];
      // Iterate over newData
      newData.forEach((item) => {
        // Find a matching item in hotelServiceTag based on displayName
        const matchedItem = hotelServiceTag?.find(
          (serviceItem) => serviceItem.displayName === item.displayName
        );

        // If a matching item is found, update isChecked in newData to true
        if (matchedItem) {
          item.isChecked = true;
          SetFilterSelectedTags((prevTags) => [...prevTags, item]);
        } else {
          // If no match is found, set isChecked to false (optional, depending on your logic)
          item.isChecked = false;
        }
      });
      const groupedData = newData.reduce((acc, obj) => {
        const key = obj.groupTitle || "No Group";
        const group = acc?.find((item) => item.groupTitle === key);
        if (group) {
          group.items.push(obj);
        } else {
          acc.push({ groupTitle: key, items: [obj] });
        }
        return acc;
      }, []);

      SetFilterTags(groupedData);

      //Structure SearchTags
      const newStructureData = [...structurSearchTags];
      const StructureData = newStructureData.reduce((acc, obj) => {
        const key = obj.groupTitle || "No Group";
        const group = acc.find((item) => item.groupTitle === key);
        if (group) {
          group.items.push(obj);
        } else {
          acc.push({ groupTitle: key, items: [obj] });
        }
        return acc;
      }, []);
      setStructureTags(StructureData);
    }
  }, [tagsResponse]);

  //handle filter checkbox
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const itemFilter = filterResult?.find(
      (item) => item.displayName.toLowerCase() === name.toLowerCase()
    );

    // If checked and item found, add it to checkedItems
    if (checked && itemFilter) {
      SetFilterSelectedTags((prevItems) => [...prevItems, itemFilter]);
      const updatedFilterValue = filterTags?.map((group) => ({
        groupTitle: group.groupTitle,
        items: group.items?.map((item) => ({
          ...item,
          isChecked: item.id === itemFilter.id ? true : item.isChecked, // Update isChecked if id matches
        })),
      }));
      SetFilterTags([...updatedFilterValue]);
    } else {
      // If unchecked, remove the item from checkedItems
      SetFilterSelectedTags((prevItems) =>
        prevItems.filter((prevItem) => prevItem.displayName !== name)
      );
      const updatedFilterValue = filterTags?.map((group) => ({
        groupTitle: group.groupTitle,
        items: group.items?.map((item) => ({
          ...item,
          isChecked: item.id === itemFilter.id ? false : item.isChecked, // Update isChecked if id matches
        })),
      }));
      SetFilterTags([...updatedFilterValue]);
    }
  };

  //breadCrumbs
  const Custom_Separator = () => (
    <Typography variant="body1" style={{ fontSize: "20px", color: "#26529c" }}>
      ›
    </Typography>
  );

  //layout
  const handleToggle = () => {
    setIsSidebarOpened(!isSidebarOpened);
  };

  //carousel
  const initialDataArr = {
    image: "",
    nomeFile: "",
    altText: "",
    titoloMetaTag: "",
    tagMetaTag: "",
    autoreMetaTag: "",
    copyrightMetaTag: "",
    redirectLink: "",
  };

  const [selectedButtons, setSelectedButtons] = useState([]);

  const handleButtonClick = (tag) => {
    const updatedSelectedButtons = [...selectedButtons];
    const isNamePresent = updatedSelectedButtons?.some(
      (button) => button.displayName === tag.displayName
    );

    if (isNamePresent) {
      const filteredButtons = updatedSelectedButtons.filter(
        (button) => button.displayName !== tag.displayName
      );
      setSelectedButtons(filteredButtons);
    } else {
      if (updatedSelectedButtons.length < 6) {
        updatedSelectedButtons.push({
          id: tag.id,
          code: tag.code,
          displayName: tag.displayName,
          groupTitle: tag.groupTitle,
          status: tag.status,
          tagType: tag.tagType,
          images: [],
          icon: tag.icon,
        });
        setSelectedButtons(updatedSelectedButtons);
      } else {
        alert(
          "Hai raggiunto il massimo numero di servizi in evidenza da mostrare nella pagina dell’hotel. Eliminane qualcuno per poterne selezionare altri"
        );
      }
    }
  };

  const deleteHighlightedTag = (tag, index) => {
    const updatedSelectedButtons = [...selectedButtons];
    const updatedSelectedTag = updatedSelectedButtons.filter(
      (item) => item.id !== tag.id
    );
    setSelectedButtons(updatedSelectedTag);
  };

  const handleAccountability = (option) => {
    const updatedOptions = accountabilityOption.map((item) => {
      if (item.type === option.type) {
        // Toggle the isActive value
        return { ...item, isActive: !item.isActive };
      }
      return item;
    });
    SetAccountabilityOption(updatedOptions);
  };

  const handlePriceTypeChange = (option, value) => {
    const updatedOptions = accountabilityOption.map((item) => {
      if (item.type === option.type) {
        // Toggle the isActive value
        return { ...item, priceType: value };
      }
      return item;
    });
    SetAccountabilityOption(updatedOptions);
  };

  const handlePriceValueChange = (option, value) => {
    // Check if priceType is '%' and validate the value
    if (option.priceType === "%" && (value < 0 || value > 100)) {
      // Display an error message or handle the invalid value as needed
      console.error("Invalid value for percentage.");
      return;
    }

    const updatedOptions = accountabilityOption.map((item) => {
      if (item.type === option.type) {
        return { ...item, price: value };
      }
      return item;
    });
    SetAccountabilityOption(updatedOptions);
  };

  const handleDataByChange = (option, value) => {
    const updatedOptions = accountabilityOption.map((item) => {
      if (item.type === option.type) {
        return { ...item, dataBy: value };
      }
      return item;
    });
    SetAccountabilityOption(updatedOptions);
  };

  const handleSelectedDaysChange = (option, value) => {
    const updatedOptions = accountabilityOption.map((item) => {
      if (item.type === option.type) {
        return { ...item, selectedDays: value };
      }
      return item;
    });
    SetAccountabilityOption(updatedOptions);
  };

  const handleSelectedDateChange = (option, value) => {
    const updatedOptions = accountabilityOption.map((item) => {
      if (item.type === option.type) {
        return { ...item, selectedDate: value };
      }
      return item;
    });
    SetAccountabilityOption(updatedOptions);
  };

  const setSelectedEmojisHighlighted = (index, value) => {
    const newData = [...selectedButtons];
    newData[index].icon = value;
    setSelectedButtons(newData);
  };

  const setSelectedImagesHighlighted = (index, values) => {
    const newData = [...selectedButtons];
    newData[index].images = values;
    setSelectedButtons(newData);
  };

  // const [dataArr, setDataArr] = useState(null);
  const [dataArr, setDataArr] = useState([]);
  const [img, setImg] = useState([]);
  const [hotelDetailsData, sethotelDetailsData] = useState(null);
  const [hotelExtension, setHotelExtension] = useState([]);
  const [take, setTake] = useState(1000);
  const [skip, setSkip] = useState(0);
  let { code } = useParams();
  //reasons to book
  const [reasonSelectedEmojis, setReasonSelectedEmojis] = useState({
    green: "",
    red: "",
    blue: "",
    yellow: "",
  });

  const breadcrumbs = [];

  const setReasonSelectEmoji = (key, emoji) => {
    const newValue = { ...reasonSelectedEmojis };
    newValue[key] = emoji;
    setReasonSelectedEmojis(newValue);
    handleReasonsToBookChange(key, null, emoji);
  };
  //service tags all list
  const [serviceTagListData, setServiceTagListData] = useState([]);

  const setServiceTagSelectedEmojis = (index, value) => {
    const newData = [...serviceTagListData];
    newData[index].icon = value;
    setServiceTagListData(newData);
  };

  const handleDeleteServiceTag = (index) => {
    const updatedServiceTagList = serviceTagListData.filter(
      (_, i) => i !== index
    );
    setServiceTagListData(updatedServiceTagList);
  };

  const [roomHandlingData, setRoomHandlingData] = useState([
    { roomCode: "", images: [], roomDescription: "" },
  ]);

  useEffect(() => {
    const body = { skip, take };
    dispatchFetchAllHotels(body);
    dispatchFetchHotelImages(code);
    dispatchFetchHotelDetails(code);
    dispatchFetchAllTags();
  }, []);

  useMemo(() => {
    const formatImageCards = (images) => {
      let imagesResult = [];
      let imageDetails = {};
      for (const property in images) {
        imageDetails = images[property];
        imagesResult.push({
          image: imageDetails.imageUrl,
        });
      }
      // setDataArr(imagesResult);
    };

    if (
      hotelImageResponse &&
      hotelImageResponse?.images &&
      hotelImageResponse?.images?.length > 0
    ) {
      formatImageCards(hotelImageResponse.images);
    }
  }, [hotelImageResponse]);

  useMemo(() => {
    var dataExt = hotelDataDataExtentions?.items;
    setHotelExtension(dataExt);
  }, [hotelDataDataExtentions]);

  useMemo(() => {
    if (
      hoteDetailsResponse != null &&
      hoteDetailsResponse.id != null &&
      hoteDetailsResponse.id != ""
    ) {
      let newBookingReasonsEmoji = { ...reasonSelectedEmojis };
      hoteDetailsResponse?.bookingReasons?.forEach((br) => {
        newBookingReasonsEmoji[br.color] = br.icon;
      });
      setReasonSelectedEmojis(newBookingReasonsEmoji);
      setImg(hoteDetailsResponse?.images);
      sethotelDetailsData(hoteDetailsResponse);

      if (
        hoteDetailsResponse?.allServiceTags != null &&
        hoteDetailsResponse?.allServiceTags.length > 0
      ) {
        setServiceTagListData(hoteDetailsResponse.allServiceTags);
      }
      if (
        hoteDetailsResponse?.description != null
      ) {
        SetHotelDescription(hoteDetailsResponse?.description);
      }
      if (
        hoteDetailsResponse.serviceTagHighlighted != null &&
        hoteDetailsResponse.serviceTagHighlighted.length > 0
      ) {
        setSelectedButtons(hoteDetailsResponse.serviceTagHighlighted);
      }
      if (
        hoteDetailsResponse.accountability != null &&
        hoteDetailsResponse.accountability.length > 0
      ) {
        hoteDetailsResponse.accountability.forEach((element) => {
          if (element?.dataBy == "DATE") {
            if (element?.selectedDate != null) {
              element.selectedDate = element?.selectedDate.split("T")[0];
            }
          }
        });
        SetAccountabilityOption(hoteDetailsResponse.accountability);
      }
      if (
        hoteDetailsResponse?.paymentInfo != null &&
        hoteDetailsResponse?.paymentInfo != undefined
      ) {
        SetPaymentInfo(hoteDetailsResponse.paymentInfo);
      }
      if (
        hoteDetailsResponse?.roomHandling != null &&
        hoteDetailsResponse?.roomHandling != undefined
      ) {
        setRoomHandlingData(hoteDetailsResponse?.roomHandling);
      }
      if (hoteDetailsResponse?.voucher != undefined) {
        setVoucherHandlingData(hoteDetailsResponse?.voucher);
      }
      if (hoteDetailsResponse?.seo != undefined) {
        setHotelSEO(hoteDetailsResponse?.seo);
      }
    }
  }, [hoteDetailsResponse]);

  // const updateDataArr = (index, newData) => {
  //   const updatedDataArr = [...dataArr];
  //   updatedDataArr[index] = { ...newData };
  //   setDataArr(updatedDataArr);
  // };

  const setTagsServiceList = (index, value) => {
    const newData = [...serviceTagListData];
    newData[index].tags = [];
    value.forEach((element) => {
      newData[index].tags.push(element);
    });
    setServiceTagListData(newData);
  };

  const setSelectedImagesServiceTagList = (index, value) => {
    const newData = [...serviceTagListData];
    newData[index].images = value;
    setServiceTagListData(newData);
  };

  const handleChangeServiceTagList = (index, value) => {
    if (value === "" && serviceTagListData.length > 1) {
      setServiceTagListData(serviceTagListData.filter((_, i) => i !== index));
    } else {
      const newData = [...serviceTagListData];
      newData[index].groupTitle = value;
      setServiceTagListData(newData);
    }
  };

  const handleAddServiceTagList = () => {
    setServiceTagListData([
      ...serviceTagListData,
      {
        groupTitle: "",
        icon: "",
        tags: [],
        images: [],
      },
    ]);
  };

  const [greenInput, setGreenInput] = useState("");
  const [redInput, setRedInput] = useState("");
  const [blueInput, setBlueInput] = useState("");
  const [yellowInput, setYellowInput] = useState("");

  const handleSEOChange = (e) => {
    const { name, value } = e.target;
    if (name == "url") {
      setHotelSEO((prevHotelSEO) => ({
        ...prevHotelSEO,
        [name]: value,
      }));
      if (value.trim() != "") {
        const regex = /^(?!.*--).+$/;
        if (regex.test(value)) {
          var dataExt = hotelExtension.filter(
            (item) =>
              item.seo && item.seo.url === value && item.hotelCode != code
          );
          if (dataExt.length == 0) {
            setUrlError(false);
            setUrlHelperText(null);
          } else {
            setUrlError(true);
            setUrlHelperText(
              "The entered URL key matches an existing key for another hotel."
            );
          }
        } else {
          setUrlError(true);
          setUrlHelperText('Input cannot contain "--"');
        }
      }
    } else {
      setHotelSEO((prevHotelSEO) => ({
        ...prevHotelSEO,
        [name]: value,
      }));
    }
  };

  const handleChangeMiniDescription = (event) => {
    let inputValue = event.target.value;
    if (inputValue.length > 250) {
      inputValue = inputValue.slice(0, 250);
    }
    sethotelDetailsData({ ...hotelDetailsData, minDescription: inputValue });
  };

  //room Handling

  const handleReasonsToBookChange = (type, value, icon) => {
    if (hotelDetailsData) {
      let bookingReasons = hotelDetailsData.bookingReasons ?? [];
      const reasonIndex = hotelDetailsData.bookingReasons?.findIndex(
        (b) => b.color == type
      );
      if (value) {
        if (reasonIndex > -1) {
          bookingReasons[reasonIndex].description = value;
        } else {
          bookingReasons.push({ color: type, description: value, icon: icon });
        }
      } else {
        if (reasonIndex > -1 && icon) {
          bookingReasons[reasonIndex].icon = icon;
        }
      }

      sethotelDetailsData({
        ...hotelDetailsData,
        bookingReasons: bookingReasons,
      });
    }
  };

  const handleAddRoomHandling = () => {
    setRoomHandlingData([
      ...roomHandlingData,
      {
        roomCode: "",
        images: [],
        roomDescription: "",
      },
    ]);
  };

  const setValues = (index, value) => {
    const newData = [...roomHandlingData];
    newData[index].roomCode = value;
    setRoomHandlingData(newData);
  };

  // const handleRoomHandlingChange = (index, e) => {
  //   if (e.target.value === "none" && roomHandlingData.length > 1) {
  //     setRoomHandlingData(roomHandlingData.filter((_, i) => i !== index));
  //   } else {
  //     setValues(index, e.target.value);
  //   }
  // };

  const handleRoomHandlingChange = (index, value) => {
    setRoomHandlingData((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], roomCode: value };
      return newData;
    });
  };

  const setSelectedImages = (index, value) => {
    setRoomHandlingData((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], images: value };
      return newData;
    });
  };

  const handleRoomDescription = (index, value) => {
    setRoomHandlingData((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], roomDescription: value };
      return newData;
    });
  };

  // transport Tags
  const [transportTags, setTransportTags] = useState([]);

  const handleChangewhatsapp = (event) => {
    const inputValue = event.target.value;
    if (/^[+\d\s]*$/.test(inputValue)) {
      sethotelDetailsData({
        ...hotelDetailsData,
        phoneClient: {
          phoneNumber: inputValue,
          label: hotelDetailsData.hotelCode,
          isDisplayed: hotelDetailsData.phoneClient?.isDisplayed,
        },
      });
    }
  };

  const handleChangeIBAN = (event) => {
    const inputValue = event.target.value;
    sethotelDetailsData({
      ...hotelDetailsData,
      iban: {
        iBANNumber: inputValue,
        label: hotelDetailsData.hotelCode,
        isDisplayed: hotelDetailsData.iban?.isDisplayed,
      },
    });
  };

  const handleChangeAccountNumber = (event) => {
    const Value = event.target.value;

    sethotelDetailsData((prevData) => ({
      ...prevData,
      iban: {
        ...prevData.iban,
        accountName: Value,
      },
    }));
  };

  const handleChange = (event) => {
    let phoneClient = {
      phoneNumber: hotelDetailsData.phoneClient?.phoneNumber,
      label: hotelDetailsData.hotelCode,
      isDisplayed: event.target.checked,
    };
    sethotelDetailsData({ ...hotelDetailsData, phoneClient: phoneClient });
  };
  const handleChangeIBN = (event) => {
    let iBAN = {
      ibanNumber: hotelDetailsData.iban?.ibanNumber,
      label: hotelDetailsData.hotelCode,
      isDisplayed: event.target.checked,
    };
    sethotelDetailsData({ ...hotelDetailsData, iban: iBAN });
  };

  const handleVideoURLChange = (event) => {
    sethotelDetailsData({ ...hotelDetailsData, videoUrl: event.target.value });
  };

  const handlePriorityChange = (event) => {
    const priorityValue = event.target.value;
    // Check if the input value is a number and is less than or equal to 100
    if (!isNaN(priorityValue) && priorityValue <= 100) {
      sethotelDetailsData({
        ...hotelDetailsData,
        priorityField: priorityValue,
      });
    }
  };

  const handleCodiceRateChange = (event) => {
    const codiceRateValue = event.target.value;
    sethotelDetailsData({
      ...hotelDetailsData,
      codiceRate: codiceRateValue,
    });
  };

  const [isLoading, setIsLoading] = useState(false);

  //save data while salva click
  const handleSalvaClick = async () => {
    if (urlError) {
      alert(urlHelperText);
      return;
    }
    setIsLoading(true);
    // const contentState = editorState?.getCurrentContent();
    // const description_html = contentState
    //   ? convertToHTML({})(contentState)
    //   : "";
    const bodyData = {
      id: hotelDetailsData?.id,
      hotelCode:
        hotelDetailsData?.hotelCode == null
          ? code
          : hotelDetailsData?.hotelCode,
      serviceTags: filterSelectedTags,
      serviceTagHighlighted: selectedButtons,
      AllServiceTags: serviceTagListData,
      accountability: accountabilityOption,
      paymentInfo: paymentInfo,
      description: hotelDescription,
      images: dataArr,
      phoneClient: hotelDetailsData?.phoneClient,
      iban: hotelDetailsData?.iban,
      locationToLink: hotelDetailsData?.locationToLink,
      videoUrl: hotelDetailsData?.videoUrl,
      minDescription: hotelDetailsData?.minDescription,
      priorityField: hotelDetailsData?.priorityField,
      bookingReasons: hotelDetailsData?.bookingReasons,
      codiceRate : hotelDetailsData.codiceRate,
      roomHandling: roomHandlingData,
      voucher: voucherHandlingData,
      seo: hotelSEO,
    };
    await callUpdateHotelApi(bodyData)
      .then((res) => {
        toast.success("Le modifiche sono state salvate");
        navigate(`/hotel`);
      })
      .catch((e) => {
        toast.error(`Error while updating data : ${e}`);
        console.warn("Error while updating data :", e);
      });

    setIsLoading(false);
  };

  const handleDeleteRoomHandling = (index) => {
    const updatedRoomHandlingData = [...roomHandlingData];
    updatedRoomHandlingData.splice(index, 1);
    setRoomHandlingData(updatedRoomHandlingData);
  };

  //voucher
  const handleAddDynamicComponent = () => {
    setVoucherHandlingData([
      ...voucherHandlingData,
      {
        nameVouchers: "",
        creationDate: null,
        activation: "",
        valid: false,
        voucherCode: "",
        discount: "",
        priceType: "",
      },
    ]);
  };

  const handleVoucherPriceTypeChange = (option, value) => {
    const updatedOptions = voucherHandlingData.map((item) => {
      if (item.type === option.type) {
        // Toggle the isActive value
        return { ...item, priceType: value };
      }
      return item;
    });
    setVoucherHandlingData(updatedOptions);
  };

  const deleteRow = (rowIndex) => {
    const newData = [...voucherHandlingData];
    newData.splice(rowIndex, 1);
    setVoucherHandlingData(newData);
  };

  const handleInputChange = (e, rowIndex, columnId) => {
    const newData = [...voucherHandlingData];
    newData[rowIndex][columnId] = e.target.value;
    setVoucherHandlingData(newData);
  };

  const gotoBack = () => {
    navigate(-1); // This navigates to the previous page in the history stack
  };

  return (
    <Layout handleToggle={handleToggle} isSidebarOpened={isSidebarOpened}>
      <Box sx={{ height: "48px", padding: "10px" }}>
        <Stack spacing={2} sx={{ marginLeft: "20px" }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              key="1"
              color="inherit"
              onClick={gotoBack}
              sx={{
                color: "#26529c",
                fontSize: "16px",
                fontWeight: "600",
                "&:hover": {
                  color: "#26529c",
                  fontSize: "16px",
                  fontWeight: "600",
                },
              }}
            >
              <BackIcon />
            </Link>
            ,
            <Link
              underline="hover"
              key="2"
              color="inherit"
              href="/hotel-details"
              sx={{
                color: "#26529c",
                fontSize: "16px",
                fontWeight: "600",
                "&:hover": {
                  color: "#26529c",
                  fontSize: "16px",
                  fontWeight: "600",
                },
              }}
            >
              {hotelDetailsData?.title
                ? hotelDetailsData.title
                : hoteDetailsResponse?.title
                ? hoteDetailsResponse?.title
                : "Loading..."}
            </Link>
          </Breadcrumbs>
        </Stack>
      </Box>
      <Box
        sx={{ backgroundColor: "#f2f2f2", minHeight: "90vh", padding: "40px" }}
      >
        {/* carousel */}
        <Box>
          <HotelImages dataArr={dataArr} setDataArr={setDataArr} images={img} />
        </Box>

        {/*SEO setting */}
        <Box
          sx={{
            marginTop: "30px",
            backgroundColor: "white",
            borderRadius: "10px",
            minHeight: "105px",
            padding: "15px 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
                  SEO
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    paddingTop: "6px",
                    marginLeft: "10px",
                  }}
                ></Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    width: "313px",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#C7C7C7",
                  }}
                >
                  This is the SEO section for this hotel
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box sx={{ marginLeft: "100px", width: "690px" }}>
                <TextField
                  fullWidth
                  label="URL"
                  variant="outlined"
                  name="url"
                  value={hotelSEO.url}
                  onChange={handleSEOChange}
                  placeholder={baseURL}
                  sx={{ mb: 2, backgroundColor: "white", borderRadius: 1 }}
                  error={urlError}
                  helperText={urlHelperText}
                />
                <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                  <TextField
                    fullWidth
                    label="Meta Title"
                    variant="outlined"
                    name="metaTitle"
                    value={hotelSEO.metaTitle}
                    onChange={handleSEOChange}
                    sx={{ backgroundColor: "white", borderRadius: 1 }}
                  />
                  <TextField
                    fullWidth
                    label="Meta Description"
                    variant="outlined"
                    name="metaDescription"
                    value={hotelSEO.metaDescription}
                    onChange={handleSEOChange}
                    sx={{ backgroundColor: "white", borderRadius: 1 }}
                  />
                </Box>
                <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                  <TextField
                    fullWidth
                    label="Valutazione"
                    variant="outlined"
                    name="ratingValue"
                    value={hotelSEO.ratingValue}
                    onChange={handleSEOChange}
                    sx={{ backgroundColor: "white", borderRadius: 1 }}
                  />
                  <TextField
                    fullWidth
                    label="Numero Valutazioni"
                    variant="outlined"
                    name="reviewCount"
                    value={hotelSEO.reviewCount}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*\.?\d*$/.test(value)) {
                        handleSEOChange(e); 
                      }
                    }}
                    type="text"
                    sx={{ backgroundColor: "white", borderRadius: 1 }}
                  />
                </Box>
                <TextField
                  fullWidth
                  label="Fascia di Prezzo (100€ - 200€)"
                  variant="outlined"
                  name="priceRange"
                  value={hotelSEO.priceRange}
                  onChange={handleSEOChange}
                  sx={{ backgroundColor: "white", borderRadius: 1 }}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Video url */}
        <Box
          sx={{
            marginTop: "30px",
            backgroundColor: "white",
            borderRadius: "10px",
            height: "105px",
            padding: "15px 20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
                Video Hotel Youtube URL
              </Typography>{" "}
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  paddingTop: "6px",
                  marginLeft: "10px",
                }}
              >
                (opzionale)
              </Typography>
            </Box>
            <Typography
              sx={{
                width: "313px",
                fontSize: "15px",
                fontWeight: "500",
                color: "#C7C7C7",
              }}
            >
              Se l’url è valido il video verrà mostrato come primo elemento
              nella galleria dell’hotel
            </Typography>
          </Box>
          <TextField
            sx={{
              marginTop: "10px",
              width: "827px",
              height: "48px",
              borderRadius: "5px",
            }}
            value={hotelDetailsData?.videoUrl}
            onChange={handleVideoURLChange}
            variant="outlined"
            placeholder="https://www.youtube.com/watch?v=XdzguMjIfy0"
          />
        </Box>

        {/* Service tags */}
        <Box
          sx={{
            marginTop: "30px",
            backgroundColor: "white",
            borderRadius: "10px",
            minHeight: "571px",
            padding: "15px 20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography sx={{ fontSize: "24px", fontWeight: "500px" }}>
              Filtri Motore di Ricerca
            </Typography>
            <Typography
              sx={{
                width: "313px",
                fontSize: "15px",
                fontWeight: "400",
                color: "#C7C7C7",
              }}
            >
              Questi tags se selezionati nell’hotel verranno mostrati nella
              sezione dei filtri a sinistra, presente nella pagina dei risultati
              quando l’hotel sarà presente nei risultati.
            </Typography>
          </Box>
          <Box sx={{ marginLeft: "100px", width: "690px" }}>
            <div></div>
            {filterTags?.map((tag, index) => (
              <Grid key={index}>
                <Box sx={{ display: "flex", marginTop: "50px" }}>
                  {/* <Checkbox
                  {...label}
                  sx={{ width: "16px", height: "16px", marginTop: "4px" }}
                /> */}
                  <Typography
                    sx={{
                      marginLeft: "10px",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    {tag.groupTitle}
                  </Typography>
                </Box>
                <Divider />
                <Box sx={{ width: "100%", marginTop: "10px" }}>
                  {Array.isArray(tag.items) && tag.items.length > 0 ? (
                    <SelectCheckbox
                      label={label}
                      items={tag.items}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                  ) : (
                    <Typography>No items available</Typography>
                  )}
                </Box>
              </Grid>
            ))}
          </Box>
        </Box>

        {/* Service Tags Highlighted (buttons) */}
        <Box
          sx={{
            marginTop: "30px",
            backgroundColor: "white",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            minHeight: "245px",
            padding: "15px 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
                  Servizi in Evidenza{" "}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    paddingTop: "6px",
                    marginLeft: "10px",
                  }}
                >
                  (max 6)
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    width: "313px",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#C7C7C7",
                  }}
                >
                  Questi tags se selezionati nell’hotel verranno mostrati nei
                  primi 6 servizi in evidenza nella pagina hotel.
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "827px" }}
            >
              <Box
                sx={{
                  marginLeft: { xs: "0px", md: "40px" },
                  width: "100%",
                  maxWidth: { xs: "none", md: "650px" },
                }}
              >
                {highlightedTags?.map((tag, index) => (
                  <Button
                    key={index}
                    variant="outlined"
                    sx={{
                      marginRight: "10px",
                      height: "30px",
                      fontSize: "16px",
                      fontWeight: "400",
                      textTransform: "none",
                      marginTop: { xs: "4px", md: "4px" },

                      ...(selectedButtons?.some(
                        (selected) => selected?.displayName === tag.displayName
                      ) && {
                        color: "#4167a8",
                        borderColor: "#4167a8",
                      }),
                      ...(!selectedButtons?.some(
                        (selected) => selected?.displayName === tag.displayName
                      ) && {
                        color: "#b3b3b3",
                        borderColor: "#b3b3b3",
                      }),
                    }}
                    onClick={() => handleButtonClick(tag)}
                  >
                    {tag.displayName}
                  </Button>
                ))}
              </Box>

              <Box>
                {selectedButtons?.map((item, index) => (
                  <TagsComponent
                    key={index}
                    tagName={item.displayName}
                    hotelImages={hotelDetailsData?.images}
                    selectedImages={item.images}
                    setSelectedImages={(value) =>
                      setSelectedImagesHighlighted(index, value)
                    }
                    selectedEmojis={item.icon}
                    setSelectedEmojis={(value) =>
                      setSelectedEmojisHighlighted(index, value)
                    }
                    deleteSelectedButton={() =>
                      deleteHighlightedTag(item, index)
                    }
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </Box>

        <Divider sx={{ padding: "0px 0px" }} />

        {/* service tags all list */}
        <Box
          sx={{
            backgroundColor: "white",
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            minHeight: "345px",
            padding: "20px 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: isSidebarOpened ? "column" : "row",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
                  Servizi Struttura
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    width: "313px",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#C7C7C7",
                  }}
                >
                  Questi tags se selezionati nell’hotel verranno mostrati nei
                  servizi della struttura nella pagina hotel.
                </Typography>
              </Box>
            </Box>
            <Box>
              {serviceTagListData?.map((item, index) => (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box sx={{ margin: "0px 0px 10px 40px", display: "flex" }}>
                    <Box
                      sx={{
                        margin: "0px 10px",
                        fontSize: "15px",
                        fontWeight: "400",
                      }}
                    >
                      <Select
                        style={{ width: "818px", height: "48px" }}
                        value={item.groupTitle}
                        onChange={(e) =>
                          handleChangeServiceTagList(index, e.target.value)
                        }
                      >
                        {structureTags.map((group, idx) => (
                          <MenuItem key={idx} value={group.groupTitle}>
                            {group.groupTitle}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                    <Button
                      onClick={() => handleDeleteServiceTag(index)}
                      sx={{
                        marginLeft: "10px",
                        width: "40px",
                        height: "48px",
                        border: "1px dashed",
                        borderColor: "#b3b3b3",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "8px",
                      }}
                    >
                      <img
                        src={deleteIcon}
                        alt="icon"
                        style={{ height: "40px" }}
                      />
                    </Button>
                  </Box>
                  <Box sx={{ marginLeft: "10px" }}>
                    <Box sx={{ margin: "10px 0px 10px 30px" }}>
                      <DropdownTags
                        tags={item.tags}
                        structureTags={
                          structureTags.find(
                            (group) => group.groupTitle === item.groupTitle
                          )?.items || []
                        }
                        setTags={(value) => setTagsServiceList(index, value)}
                      />
                    </Box>
                  </Box>

                  <ImageSelect
                    hotelImages={hotelDetailsData.images}
                    selectedImages={item.images}
                    setSelectedImages={(value) =>
                      setSelectedImagesServiceTagList(index, value)
                    }
                  />
                </Box>
              ))}
              <Button
                sx={{
                  margin: "10px 0px 10px 40px",
                  width: "200px",
                  height: "31px",
                  border: "1px solid black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                  textTransform: "none",
                  color: "black",
                }}
                onClick={handleAddServiceTagList}
              >
                <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
                  Aggiungi Nuova Lista
                </Typography>
                <img
                  src={addIcon}
                  alt="icon"
                  style={{ marginLeft: "5px", width: "16px", height: "16px" }}
                />
              </Button>
            </Box>
          </Box>
        </Box>

        {/* Reasons to Book */}
        <Box
          sx={{
            marginTop: "30px",
            backgroundColor: "white",
            borderRadius: "10px",
            minHeight: "266px",
            padding: "15px 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: isSidebarOpened ? "column" : "row",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
                  Ragioni per cui Prenotare
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    paddingTop: "6px",
                    marginLeft: "10px",
                  }}
                >
                  (opzionale)
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    width: "313px",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#C7C7C7",
                  }}
                >
                  Le ragioni per cui prenotare verranno mostrate nella prima
                  sezione della pagina dell’hotel
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "40px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <IconSelect
                  icon={greenIcon}
                  color="green"
                  selectedEmojis={reasonSelectedEmojis.green}
                  setSelectedEmojis={setReasonSelectEmoji}
                  borderColor="#00AA00"
                  isEditable={true}
                />
                <Box
                  style={{
                    margin: "0px 10px",
                    width: "818px",
                    height: "48px",
                    border: "1px solid #00AA00",
                    borderRadius: "5px",
                  }}
                >
                  <input
                    placeholder="Max 35 chars"
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "none",
                      borderRadius: "5px",
                      padding: "8px",
                      fontSize: "18px",
                    }}
                    value={
                      hotelDetailsData?.bookingReasons?.find(
                        (h) => h.color == "green"
                      )?.description
                    }
                    onChange={(e) => {
                      handleReasonsToBookChange("green", e.target.value);
                    }}
                    maxLength={35}
                  />
                </Box>
              </Box>
              <Box sx={{ display: "flex", marginTop: "10px" }}>
                <IconSelect
                  icon={redIcon}
                  color="red"
                  selectedEmojis={reasonSelectedEmojis.red}
                  setSelectedEmojis={setReasonSelectEmoji}
                  borderColor="#F80B0B"
                  isEditable={true}
                />
                <Box
                  style={{
                    margin: "0px 10px",
                    width: "818px",
                    height: "48px",
                    border: "1px solid #F80B0B",
                    borderRadius: "5px",
                  }}
                >
                  <input
                    placeholder="Max 35 chars"
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "none",
                      borderRadius: "5px",
                      padding: "8px",
                      fontSize: "18px",
                    }}
                    value={
                      hotelDetailsData?.bookingReasons?.find(
                        (h) => h.color == "red"
                      )?.description
                    }
                    onChange={(e) => {
                      handleReasonsToBookChange("red", e.target.value);
                    }}
                    maxLength={35}
                  />
                </Box>
              </Box>
              <Box sx={{ display: "flex", marginTop: "10px" }}>
                <IconSelect
                  icon={blueIcon}
                  borderColor="#26529C"
                  color="blue"
                  selectedEmojis={reasonSelectedEmojis.blue}
                  setSelectedEmojis={setReasonSelectEmoji}
                  isEditable={true}
                />
                <Box
                  style={{
                    margin: "0px 10px",
                    width: "818px",
                    height: "48px",
                    border: "1px solid #26529C",
                    borderRadius: "5px",
                  }}
                >
                  <input
                    placeholder="Max 35 chars"
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "none",
                      borderRadius: "5px",
                      padding: "8px",
                      fontSize: "18px",
                    }}
                    value={
                      hotelDetailsData?.bookingReasons?.find(
                        (h) => h.color == "blue"
                      )?.description
                    }
                    onChange={(e) => {
                      handleReasonsToBookChange("blue", e.target.value);
                    }}
                    maxLength={35}
                  />
                </Box>
              </Box>
              <Box sx={{ display: "flex", marginTop: "10px" }}>
                <IconSelect
                  icon={yellowIcon}
                  borderColor="#F68013"
                  color="yellow"
                  selectedEmojis={reasonSelectedEmojis.yellow}
                  setSelectedEmojis={setReasonSelectEmoji}
                  isEditable={true}
                />
                <Box
                  style={{
                    margin: "0px 10px",
                    width: "818px",
                    height: "48px",
                    border: "1px solid #F68013",
                    borderRadius: "5px",
                  }}
                >
                  <input
                    placeholder="Max 35 chars"
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "none",
                      borderRadius: "5px",
                      padding: "8px",
                      fontSize: "18px",
                    }}
                    value={
                      hotelDetailsData?.bookingReasons?.find(
                        (h) => h.color == "yellow"
                      )?.description
                    }
                    onChange={(e) => {
                      handleReasonsToBookChange("yellow", e.target.value);
                    }}
                    maxLength={35}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* MiniDescription */}
        <Box
          sx={{
            marginTop: "30px",
            backgroundColor: "white",
            borderRadius: "10px",
            minHeight: "210px",
            padding: "15px 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: isSidebarOpened ? "column" : "row",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
                  MiniDescrizione
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    paddingTop: "6px",
                    marginLeft: "10px",
                  }}
                >
                  (opzionale)
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    width: "313px",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#C7C7C7",
                  }}
                >
                  La minidescrizione sarà presente sia nelle email di
                  prenotazione e preventivo, che nella prima sezione della
                  pagina dell’hotel, massimo 250 caratteri. Se non viene
                  inserita verranno recuperati i primi 250 caratteri dalla
                  descrizione
                </Typography>
              </Box>
            </Box>
            <Box
              style={{
                margin: "0px 10px 10px 40px",
                width: "872px",
                borderRadius: "5px",
              }}
            >
              <TextField
                placeholder="MiniDescription"
                multiline
                rows={6}
                value={hotelDetailsData?.minDescription}
                onChange={handleChangeMiniDescription}
                style={{
                  width: "100%",
                  padding: "8px",
                }}
              />
            </Box>
          </Box>
        </Box>

        {/* Description */}
        <Box
          sx={{
            marginTop: "30px",
            backgroundColor: "white",
            borderRadius: "10px",
            minHeight: "316px",
            padding: "15px 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: isSidebarOpened ? "column" : "row",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
                  Descrizione
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    paddingTop: "6px",
                    marginLeft: "10px",
                  }}
                >
                  (opzionale)
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    width: "313px",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#C7C7C7",
                  }}
                >
                  La descrizione sarà presente nella sezione delle informazioni
                  sull’hotel nella pagina dell’hotel
                </Typography>
              </Box>
            </Box>
            <Box
              style={{
                margin: "0px 10px 10px 40px",
                width: "872px",
                border: "1px solid #e3e3e3",
                borderRadius: "5px",
                padding: "10px",
                minHeight: "316px",
              }}
            >
            <div data-color-mode="light">
              <MDEditor
                value={hotelDescription}
                onChange={SetHotelDescription}
                previewOptions={{
                  rehypePlugins: [[rehypeSanitize]],
                }}
              />
            </div>
            </Box>
          </Box>
        </Box>

        {/* Room Handling */}
        <Box
          sx={{
            marginTop: "30px",
            backgroundColor: "white",
            borderRadius: "10px",
            minHeight: "30px",
            padding: "15px 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: isSidebarOpened ? "column" : "row",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
                  Gestione Informazioni Stanze
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    paddingTop: "6px",
                    marginLeft: "10px",
                  }}
                >
                  (opzionale)
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    width: "313px",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#C7C7C7",
                  }}
                ></Typography>
              </Box>
            </Box>
            <Box>
              {roomHandlingData?.map((item, index) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "20px",
                  }}
                >
                  <Box sx={{ margin: "0px 0px 10px 40px", display: "flex" }}>
                    {/* <Select
                      value={item.roomCode}
                      onChange={(e) => handleRoomHandlingChange(index, e)}
                      fullWidth
                      style={{ width: "800px", height: "48px" }}
                    >
                      {roomData?.map((item, index) => (
                        <MenuItem value={item?.roomCode}>
                          {item?.roomCode} - {item?.roomDescription}
                        </MenuItem>
                      ))}
                    </Select> */}
                    <TextField
                      sx={{
                        width: { xs: "auto", sm: "876px" },
                        height: "48px",
                        borderRadius: "5px",
                      }}
                      value={item.roomCode}
                      onChange={(e) =>
                        handleRoomHandlingChange(index, e.target.value)
                      }
                      variant="outlined"
                      placeholder="Room Code"
                    />

                    <Button
                      onClick={() => handleDeleteRoomHandling(index)}
                      sx={{
                        marginLeft: "10px",
                        width: "40px",
                        height: "48px",
                        border: "1px dashed",
                        borderColor: "#b3b3b3",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "8px",
                      }}
                    >
                      <img
                        src={deleteIcon}
                        alt="icon"
                        style={{ height: "40px" }}
                      />
                    </Button>
                  </Box>

                  <Box sx={{ marginLeft: "10px" }}>
                    <Box sx={{ margin: "0px 0px 10px 30px", width: "876px" }}>
                      <TextField
                        sx={{
                          width: { xs: "auto", sm: "876px" },
                          height: "48px",
                          borderRadius: "5px",
                        }}
                        value={item.roomDescription}
                        onChange={(e) =>
                          handleRoomDescription(index, e.target.value)
                        }
                        variant="outlined"
                        placeholder="Room Description"
                      />
                    </Box>
                  </Box>

                  <ImageSelect
                    hotelImages={hoteDetailsResponse.images}
                    selectedImages={item.images}
                    setSelectedImages={(value) =>
                      setSelectedImages(index, value)
                    }
                  />
                </Box>
              ))}

              <Button
                sx={{
                  margin: "10px 0px 10px 40px",
                  width: "200px",
                  height: "31px",
                  border: "1px solid black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                  textTransform: "none",
                  color: "black",
                }}
                onClick={handleAddRoomHandling}
              >
                <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
                  Aggiungi Nuova Lista
                </Typography>
                <img
                  src={addIcon}
                  alt="icon"
                  style={{ marginLeft: "5px", width: "16px", height: "16px" }}
                />
              </Button>
            </Box>
          </Box>
        </Box>

        {/* Transport Handling */}
        {/* <Box
          sx={{
            marginTop: "30px",
            backgroundColor: "white",
            borderRadius: "10px",
            minHeight: "105px",
            padding: "15px 20px",
            display: "flex",
            flexDirection: isSidebarOpened ? "column" : "row",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
                  Transport Handling
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    paddingTop: "6px",
                    marginLeft: "10px",
                  }}
                >
                  (optional)
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    width: "313px",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#C7C7C7",
                  }}
                >
                  If not inserted will be taken from Aves if available
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TagsInputField tags={transportTags} setTags={setTransportTags} />
          </Box>
        </Box> */}

        {/* Whatsapp Button */}
        <Box
          sx={{
            marginTop: "30px",
            backgroundColor: "white",
            borderRadius: "10px",
            minHeight: "105px",
            padding: "15px 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
                  Pulsante Whatsapp
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    paddingTop: "6px",
                    marginLeft: "10px",
                  }}
                >
                  (opzionale)
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    width: "313px",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#C7C7C7",
                  }}
                >
                  Se attivato (on) e con un numero valido, verrà mostrato un
                  pulsante whatsapp nella pagina dell’hotel{" "}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                marginLeft: { xs: 0, sm: "40px" },
                height: "48px",
                width: { xs: "auto", sm: "876px" },
                borderRadius: "5px",
                marginTop: { xs: "10px", sm: 0 },
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // padding: "8px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  position: "relative",
                  display: "inline-block",
                }}
              >
                <TextField
                  sx={{
                    marginTop: "10px",
                    width: { xs: "auto", sm: "876px" },
                    height: "48px",
                    borderRadius: "5px",
                  }}
                  value={hotelDetailsData?.phoneClient?.phoneNumber}
                  onChange={handleChangewhatsapp}
                  type="phone"
                  variant="outlined"
                  placeholder="Insert Whatsapp Number"
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    position: "absolute",
                    right: "20px",
                    top: 0,
                    marginTop: "20px",
                  }}
                >
                  <Switch
                    {...label1}
                    checked={hotelDetailsData?.phoneClient?.isDisplayed}
                    onChange={handleChange}
                  />
                  <span style={{ marginTop: "5px" }}>
                    {hotelDetailsData?.phoneClient?.isDisplayed ? "on" : "off"}
                  </span>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Tutti i pagamenti all’hotel? */}
        <Box
          sx={{
            marginTop: "30px",
            backgroundColor: "white",
            borderRadius: "10px",
            minHeight: "150px",
            padding: "40px 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
                  Tutti i pagamenti all’hotel?
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    paddingTop: "6px",
                    marginLeft: "10px",
                  }}
                ></Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    width: "313px",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#C7C7C7",
                  }}
                >
                  Attivando questa opzione la gestione pagamenti si riferirà
                  direttamente all'iban
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                marginLeft: { xs: 0, sm: "40px" },
                height: "48px",
                width: { xs: "auto", sm: "876px" },
                borderRadius: "5px",
                marginTop: { xs: "10px", sm: 0 },
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // padding: "8px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  position: "relative",
                  display: "inline-block",
                }}
              >
                <TextField
                  sx={{
                    marginTop: "10px",
                    width: { xs: "auto", sm: "876px" },
                    height: "48px",
                    borderRadius: "5px",
                  }}
                  value={hotelDetailsData?.iban?.ibanNumber}
                  onChange={handleChangeIBAN}
                  type="phone"
                  variant="outlined"
                  placeholder="Inserisci iban"
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    position: "absolute",
                    right: "20px",
                    top: 0,
                    marginTop: "20px",
                  }}
                >
                  <Switch
                    {...label1}
                    checked={hotelDetailsData?.iban?.isDisplayed}
                    onChange={handleChangeIBN}
                  />
                  <span style={{ marginTop: "5px" }}>
                    {hotelDetailsData?.iban?.isDisplayed ? "on" : "off"}
                  </span>
                </Box>
                <Box>
                  <TextField
                    sx={{
                      marginTop: "15px",
                      width: { xs: "auto", sm: "876px" },
                      height: "48px",
                      borderRadius: "5px",
                    }}
                    onChange={handleChangeAccountNumber}
                    value={hotelDetailsData?.iban?.accountName}
                    variant="outlined"
                    placeholder="Intestazione conto"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Metodi di Pagamento Abilitati text */}
        <Box
          sx={{
            marginTop: "30px",
            backgroundColor: "white",
            borderRadius: "10px",
            minHeight: "105px",
            padding: "15px 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
                  Gestione Pagamenti
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    paddingTop: "6px",
                    marginLeft: "10px",
                  }}
                ></Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    width: "313px",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#C7C7C7",
                  }}
                >
                  Qui si può inserire la logica dei pagamenti delle vacanze per
                  questo Hotel. Se questa sezione rimane vuota il pagamento
                  della vacanza verrà saldato tutto subito.
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { xs: "auto", sm: "876px" },
                justifyContent: "space-between",
                alignItems: "start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                }}
              >
                {accountabilityOption?.map((option, index) => (
                  <Button
                    key={index}
                    variant="outlined"
                    sx={{
                      marginRight: "10px",
                      height: "30px",
                      width: "100%",
                      fontSize: "16px",
                      fontWeight: "400",
                      textTransform: "none",
                      marginTop: { xs: "4px", md: "4px" },

                      ...(option.isActive == true && {
                        color: "#4167a8",
                        borderColor: "#4167a8",
                      }),
                      ...(option.isActive == false && {
                        color: "#b3b3b3",
                        borderColor: "#b3b3b3",
                      }),
                    }}
                    onClick={() => handleAccountability(option)}
                  >
                    {option.title}
                  </Button>
                ))}
              </Box>

              {accountabilityOption.map(
                (option) =>
                  option.isActive && (
                    <Box key={option.value} sx={{ marginTop: "20px" }}>
                      <Typography sx={{ fontSize: "15px", fontWeight: "500" }}>
                        {option.title}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          width: { xs: "auto", sm: "876px" },
                        }}
                      >
                        <Select
                          sx={{ width: "15%" }}
                          value={option.priceType}
                          onChange={(e) =>
                            handlePriceTypeChange(option, e.target.value)
                          }
                        >
                          <MenuItem value={"€"}>€</MenuItem>
                          <MenuItem value={"%"}>%</MenuItem>
                        </Select>
                        <TextField
                          sx={{
                            borderRadius: "5px",
                          }}
                          type="number"
                          variant="outlined"
                          placeholder="Number"
                          value={option.price}
                          onChange={(e) =>
                            handlePriceValueChange(option, e.target.value)
                          }
                        />
                        {option.type !== "SCONTOUNICO" && (
                          <>
                            <Select
                              sx={{ width: "100%", margin: "0px 10px" }}
                              value={option.dataBy}
                              onChange={(e) =>
                                handleDataByChange(option, e.target.value)
                              }
                            >
                              <MenuItem value={"DATE"}>Entro la Data</MenuItem>
                              <MenuItem value={"DAYS"}>
                                {getOptionText(option.type)}
                              </MenuItem>
                            </Select>
                            {option.dataBy === "DATE" ? (
                              <TextField
                                sx={{
                                  borderRadius: "5px",
                                  width: "300px",
                                }}
                                type="date"
                                variant="outlined"
                                placeholder="date"
                                value={option.selectedDate}
                                onChange={(e) =>
                                  handleSelectedDateChange(
                                    option,
                                    e.target.value
                                  )
                                }
                              />
                            ) : (
                              <TextField
                                sx={{
                                  borderRadius: "5px",
                                  width: "300px",
                                }}
                                type="number"
                                variant="outlined"
                                placeholder="Giorni"
                                value={option.selectedDays}
                                onChange={(e) =>
                                  handleSelectedDaysChange(
                                    option,
                                    e.target.value
                                  )
                                }
                              />
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                  )
              )}
              <Box></Box>
            </Box>
          </Box>
        </Box>

        {/* Metodi di Pagamento Abilitati text */}
        <Box
          sx={{
            marginTop: "30px",
            backgroundColor: "white",
            borderRadius: "10px",
            minHeight: "105px",
            padding: "15px 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
                  Metodi di Pagamento Abilitati
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    paddingTop: "6px",
                    marginLeft: "10px",
                  }}
                ></Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    width: "313px",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#C7C7C7",
                  }}
                >
                  Verificare che non ci siano mismatch con i ricavi degli hotel
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { xs: "auto", sm: "876px" },
                justifyContent: "space-between",
                alignItems: "start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <Button
                  key="IsCarta"
                  variant="outlined"
                  sx={{
                    marginRight: "10px",
                    height: "30px",
                    width: "100%",
                    fontSize: "16px",
                    fontWeight: "400",
                    textTransform: "none",
                    marginTop: { xs: "4px", md: "4px" },

                    ...(paymentInfo.isCarta == true && {
                      color: "#4167a8",
                      borderColor: "#4167a8",
                    }),
                    ...(paymentInfo.isCarta == false && {
                      color: "#b3b3b3",
                      borderColor: "#b3b3b3",
                    }),
                  }}
                  onClick={() => handlePaymentToggle("isCarta")}
                >
                  Carta
                </Button>

                <Button
                  key="IsBankTransfer"
                  variant="outlined"
                  sx={{
                    marginRight: "10px",
                    height: "30px",
                    width: "100%",
                    fontSize: "16px",
                    fontWeight: "400",
                    textTransform: "none",
                    marginTop: { xs: "4px", md: "4px" },

                    ...(paymentInfo.isBankTransfer == true && {
                      color: "#4167a8",
                      borderColor: "#4167a8",
                    }),
                    ...(paymentInfo.isBankTransfer == false && {
                      color: "#b3b3b3",
                      borderColor: "#b3b3b3",
                    }),
                  }}
                  onClick={() => handlePaymentToggle("isBankTransfer")}
                >
                  Bonifico
                </Button>

                <Button
                  key="IsScalapay"
                  variant="outlined"
                  sx={{
                    marginRight: "10px",
                    height: "30px",
                    width: "100%",
                    fontSize: "16px",
                    fontWeight: "400",
                    textTransform: "none",
                    marginTop: { xs: "4px", md: "4px" },

                    ...(paymentInfo.isScalapay == true && {
                      color: "#4167a8",
                      borderColor: "#4167a8",
                    }),
                    ...(paymentInfo.isScalapay == false && {
                      color: "#b3b3b3",
                      borderColor: "#b3b3b3",
                    }),
                  }}
                  onClick={() => handlePaymentToggle("isScalapay")}
                >
                  ScalaPay
                </Button>

                <Button
                  key="IsKlarna"
                  variant="outlined"
                  sx={{
                    marginRight: "10px",
                    height: "30px",
                    width: "100%",
                    fontSize: "16px",
                    fontWeight: "400",
                    textTransform: "none",
                    marginTop: { xs: "4px", md: "4px" },

                    ...(paymentInfo.isKlarna == true && {
                      color: "#4167a8",
                      borderColor: "#4167a8",
                    }),
                    ...(paymentInfo.isKlarna == false && {
                      color: "#b3b3b3",
                      borderColor: "#b3b3b3",
                    }),
                  }}
                  onClick={() => handlePaymentToggle("isKlarna")}
                >
                  Klarna
                </Button>
              </Box>
              <Box></Box>
            </Box>
          </Box>
        </Box>


        {/* Codice Rate*/}
        <Box
          sx={{
            marginTop: "30px",
            backgroundColor: "white",
            borderRadius: "10px",
            minHeight: "105px",
            padding: "15px 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
                  Codice Rate
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    paddingTop: "6px",
                    marginLeft: "10px",
                  }}
                ></Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    width: "313px",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#C7C7C7",
                  }}
                >
                  Questo è il codice che verrà inserito nelle rate in
                  Aves quando l'utente andrà a pagare
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { xs: "auto", sm: "876px" },
                justifyContent: "space-between",
                alignItems: "start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                }}
              >
                   <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              sx={{
                marginTop: "10px",
                width: { xs: "auto", sm: "876px" },
                height: "48px",
                borderRadius: "5px",
              }}
              type="text"
              value={hotelDetailsData?.codiceRate}
              onChange={handleCodiceRateChange}
              variant="outlined"
              placeholder="Codice Rate"
            />
          </Box>

              </Box>
              <Box></Box>
            </Box>
          </Box>
        </Box>

        {/*Gift Card Voucher */}
        <Box
          sx={{
            marginTop: "30px",
            backgroundColor: "white",
            borderRadius: "10px",
            minHeight: "105px",
            padding: "15px 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
                  Voucher
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    paddingTop: "6px",
                    marginLeft: "10px",
                  }}
                ></Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    width: "313px",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#C7C7C7",
                  }}
                >
                  Se attivato (on) e <br />
                  mostrato un pulsa <br />
                  dell'hotel <br />
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Nome Vouchers</TableCell>
                        <TableCell>Attivazione</TableCell>
                        <TableCell>Data Creazione</TableCell>
                        <TableCell>Valido?</TableCell>
                        <TableCell>Codice Voucher</TableCell>
                        <TableCell>Sconto</TableCell>
                        <TableCell>Type</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {voucherHandlingData?.map((row, rowIndex) => (
                        // <Box
                        //   key={rowIndex}
                        //   sx={{
                        //     marginTop: "30px",
                        //     backgroundColor: "white",
                        //     borderRadius: "10px",
                        //     padding: "15px 20px 0px 20px",
                        //     display: "flex",
                        //     flexDirection: "row",
                        //     justifyContent: "space-between",
                        //   }}
                        // >
                        <TableRow key={rowIndex}>
                          <TableCell>
                            <IconButton onClick={() => deleteRow(rowIndex)}>
                              <DeleteIcon color="error" />
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <TextField
                              value={row.nameVouchers}
                              onChange={(e) =>
                                handleInputChange(e, rowIndex, "nameVouchers")
                              }
                              variant="outlined"
                              fullWidth
                            />
                          </TableCell>
                          <TableCell>
                            {/* <TextField
                              value={row.activation}
                              onChange={(e) => handleInputChange(e, rowIndex, 'activation')}
                              variant="outlined"
                              fullWidth
                            /> */}
                            <FormControl variant="outlined" fullWidth>
                              <Select
                                value={row.activation} // Assuming row.valid is a boolean indicating true or false
                                onChange={(e) =>
                                  handleInputChange(e, rowIndex, "activation")
                                }
                                displayEmpty
                                inputProps={{ "aria-label": "Select validity" }}
                              >
                                <MenuItem value={"SINGLE"}>SINGLE</MenuItem>
                                <MenuItem value={"MULTIPLE"}>MULTIPLE</MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <TextField
                              fullWidth
                              type="date"
                              variant="outlined"
                              value={
                                row.creationDate
                                  ? format(
                                      new Date(row.creationDate),
                                      "yyyy-MM-dd"
                                    )
                                  : null
                              }
                              onChange={(e) =>
                                handleInputChange(e, rowIndex, "creationDate")
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <FormControl variant="outlined" fullWidth>
                              <Select
                                value={row.valid} // Assuming row.valid is a boolean indicating true or false
                                onChange={(e) =>
                                  handleInputChange(e, rowIndex, "valid")
                                }
                                displayEmpty
                                inputProps={{ "aria-label": "Select validity" }}
                              >
                                <MenuItem value={true}>True</MenuItem>
                                <MenuItem value={false}>False</MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <TextField
                              value={row.voucherCode}
                              onChange={(e) =>
                                handleInputChange(e, rowIndex, "voucherCode")
                              }
                              variant="outlined"
                              fullWidth
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              type="number"
                              value={row.discount}
                              onChange={(e) =>
                                handleInputChange(e, rowIndex, "discount")
                              }
                              variant="outlined"
                              fullWidth
                            />
                          </TableCell>
                          <TableCell>
                            <Select
                              sx={{ width: "100%" }}
                              value={row?.priceType}
                              // onChange={(e) =>
                              //   handleVoucherPriceTypeChange(row, e.target.value)
                              // }
                              onChange={(e) =>
                                handleInputChange(e, rowIndex, "priceType")
                              }
                            >
                              <MenuItem value={"€"}>€</MenuItem>
                              <MenuItem value={"%"}>%</MenuItem>
                            </Select>
                          </TableCell>
                        </TableRow>
                        // </Box>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Button
                  sx={{
                    margin: "10px 0px 10px 40px",
                    width: "200px",
                    height: "31px",
                    border: "1px solid black",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "5px",
                    textTransform: "none",
                    color: "black",
                    float: "right",
                    marginTop: "40px",
                  }}
                  onClick={handleAddDynamicComponent}
                >
                  <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
                    Crea Voucher
                  </Typography>
                  <img
                    src={addIcon}
                    alt="icon"
                    style={{ marginLeft: "5px", width: "16px", height: "16px" }}
                  />
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Priority Field */}
        <Box
          sx={{
            marginTop: "30px",
            backgroundColor: "white",
            borderRadius: "10px",
            minHeight: "105px",
            padding: "15px 20px",
            display: "flex",
            flexDirection: isSidebarOpened ? "column" : "row",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
                  Campo Priorità
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    paddingTop: "6px",
                    marginLeft: "10px",
                  }}
                >
                  (opzionale)
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    width: "325px",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#C7C7C7",
                  }}
                >
                  Questo è il campo priorità, più è alto il valore e più è
                  importante, se la priorità supera 70 il motore di ricerca
                  cercherà anche date differenti da quelle inserite dall’utente
                  nell’arco di +-3 giorni
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              sx={{
                marginTop: "10px",
                width: { xs: "auto", sm: "876px" },
                height: "48px",
                borderRadius: "5px",
              }}
              type="number"
              value={hotelDetailsData?.priorityField}
              onChange={handlePriorityChange}
              variant="outlined"
              inputProps={{ min: 1, max: 100, maxLength: 3 }}
              placeholder="Insert Priority Number"
            />
          </Box>
        </Box>
        {/* Button */}
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button
            disabled={isLoading}
            variant="contained"
            sx={{ marginTop: "20px", width: "200px" }}
            onClick={handleSalvaClick}
          >
            {isLoading ? (
              <CircularProgress size={24} sx={{ color: "white" }} />
            ) : (
              "Salva"
            )}
          </Button>
        </Box>
      </Box>
    </Layout>
  );
}
