import Layout from "../../Components/Layout/Layout";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import addIcon from "../../assets/addIcon.svg";
import CheckboxGroup from "../../Components/CheckBoxGroup/CheckBoxGroup";
import CheckBoxGroupWithIcon from "../../Components/CheckBoxGroupWithIcon/CheckBoxGroupWithIcon";
import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import DynamicBox from "../../Components/LocationLink/DynamicBox";
import LHiddenDynamicBox from "../../Components/LocationHidden/LHiddenDynamicBox";
import DropdownBox from "../../Components/DropdownBox/DropdownBox";
import FilterSearchEngine from "../../Components/FilterSearchEngine/FilterSearchEngine";
import { useTagContext } from "../../library/store/slices/TagSlicer";
import useSetting from "../../library/hooks/Setting/setting";
import { useNavigate } from "react-router-dom";
import { getLocationToLinkResponseHelper } from "../../utils/helper/settings";
import { useSettingContext } from "../../library/store/slices/HotelSetting";
import useSearchHotels from "../../library/hooks/UseSearchHotels";
import { toast } from "react-toastify";
import { format } from "date-fns";
import AddLocationModel from "../../Components/AddLocationModel/AddLocationModel";
import TableApp from "../../Components/TableApp/TableApp";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function Settings() {
  const navigate = useNavigate();
  const { dispatchForSearchParamByText } = useSearchHotels();

  const [dynamicComponents, setDynamicComponents] = useState([
    {
      selectedOption: "",
      selectedTagOption: [],
    },
  ]);

  const [hiddenLocation, SetHiddenLocation] = useState([]);

  const initialState = [
    {
      title: "Pre Acconto",
      type: "PREACCONTO",
      isActive: false,
      priceType: "",
      price: 0,
      dataBy: "",
      selectedDate: null,
      selectedDays: 0,
      accountabilityType: "",
    },
    {
      title: "Acconto",
      type: "ACCONTO",
      isActive: false,
      priceType: "",
      price: 0,
      dataBy: "",
      selectedDate: null,
      selectedDays: 0,
      accountabilityType: "",
    },
    {
      title: "Saldo",
      type: "SALDO",
      isActive: false,
      priceType: "",
      price: 0,
      dataBy: "",
      selectedDate: null,
      selectedDays: 0,
      accountabilityType: "",
    },
    {
      title: "Sconto Unico",
      type: "SCONTOUNICO",
      isActive: false,
      priceType: "",
      price: 0,
      dataBy: "",
      selectedDate: null,
      selectedDays: 0,
      accountabilityType: "",
    },
  ];
  const [paymentInfo, SetPaymentInfo] = useState({
    isCarta: false,
    isBankTransfer: false,
    isScalapay: false,
    isKlarna: false,
  });

  const [gestionePagamentiNIT, setGestionePagamentiNIT] = useState([
    {
      title: "Pre Acconto",
      type: "PREACCONTO",
      isActive: false,
      priceType: "",
      price: 0,
      dataBy: "",
      selectedDate: null,
      selectedDays: 0,
      accountabilityType: "",
    },
    {
      title: "Acconto",
      type: "ACCONTO",
      isActive: false,
      priceType: "",
      price: 0,
      dataBy: "",
      selectedDate: null,
      selectedDays: 0,
      accountabilityType: "",
    },
    {
      title: "Saldo",
      type: "SALDO",
      isActive: false,
      priceType: "",
      price: 0,
      dataBy: "",
      selectedDate: null,
      selectedDays: 0,
      accountabilityType: "",
    },
    {
      title: "Sconto Unico",
      type: "SCONTOUNICO",
      isActive: false,
      priceType: "",
      price: 0,
      dataBy: "",
      selectedDate: null,
      selectedDays: 0,
      accountabilityType: "",
    },
  ]);
  const [gestionePagamentiIGN, setGestionePagamentiIGN] = useState([
    {
      title: "Pre Acconto",
      type: "PREACCONTO",
      isActive: false,
      priceType: "",
      price: 0,
      dataBy: "",
      selectedDate: null,
      selectedDays: 0,
      accountabilityType: "",
    },
    {
      title: "Acconto",
      type: "ACCONTO",
      isActive: false,
      priceType: "",
      price: 0,
      dataBy: "",
      selectedDate: null,
      selectedDays: 0,
      accountabilityType: "",
    },
    {
      title: "Saldo",
      type: "SALDO",
      isActive: false,
      priceType: "",
      price: 0,
      dataBy: "",
      selectedDate: null,
      selectedDays: 0,
      accountabilityType: "",
    },
    {
      title: "Sconto Unico",
      type: "SCONTOUNICO",
      isActive: false,
      priceType: "",
      price: 0,
      dataBy: "",
      selectedDate: null,
      selectedDays: 0,
      accountabilityType: "",
    },
  ]);
  const [gestionePagamentiESV, setGestionePagamentiESV] = useState([
    {
      title: "Pre Acconto",
      type: "PREACCONTO",
      isActive: false,
      priceType: "",
      price: 0,
      dataBy: "",
      selectedDate: null,
      selectedDays: 0,
      accountabilityType: "",
    },
    {
      title: "Acconto",
      type: "ACCONTO",
      isActive: false,
      priceType: "",
      price: 0,
      dataBy: "",
      selectedDate: null,
      selectedDays: 0,
      accountabilityType: "",
    },
    {
      title: "Saldo",
      type: "SALDO",
      isActive: false,
      priceType: "",
      price: 0,
      dataBy: "",
      selectedDate: null,
      selectedDays: 0,
      accountabilityType: "",
    },
    {
      title: "Sconto Unico",
      type: "SCONTOUNICO",
      isActive: false,
      priceType: "",
      price: 0,
      dataBy: "",
      selectedDate: null,
      selectedDays: 0,
      accountabilityType: "",
    },
  ]);
  const [gestionePagamentiATO, setGestionePagamentiATO] = useState([
    {
      title: "Pre Acconto",
      type: "PREACCONTO",
      isActive: false,
      priceType: "",
      price: 0,
      dataBy: "",
      selectedDate: null,
      selectedDays: 0,
      accountabilityType: "",
    },
    {
      title: "Acconto",
      type: "ACCONTO",
      isActive: false,
      priceType: "",
      price: 0,
      dataBy: "",
      selectedDate: null,
      selectedDays: 0,
      accountabilityType: "",
    },
    {
      title: "Saldo",
      type: "SALDO",
      isActive: false,
      priceType: "",
      price: 0,
      dataBy: "",
      selectedDate: null,
      selectedDays: 0,
      accountabilityType: "",
    },
    {
      title: "Sconto Unico",
      type: "SCONTOUNICO",
      isActive: false,
      priceType: "",
      price: 0,
      dataBy: "",
      selectedDate: null,
      selectedDays: 0,
      accountabilityType: "",
    },
  ]);
  const [transportDynamicComponents, setTransportDynamicComponents] = useState([
    { selectedOption: "", selectedTagOption: [], tags: [] },
  ]);
  const { dispatchFetchAllTags, callAddTagAPI, dispatchSetting } = useSetting();

  const { state: tagsResponse } = useTagContext();
  const { state: settingResponse } = useSettingContext();

  const [FilterSearch, SetFilterSearch] = useState([]);
  const [highlightedTags, setHighlightedTags] = useState([]);
  const [structureTags, setStructureTags] = useState([]);

  const [locations, setLocations] = useState([]);

  const [isAddLocationModalOpen, setIsAddLocationModalOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);


  const handleLoactionCloseModal = () => {
    setIsAddLocationModalOpen(false);
  };

  const handleLoactionOpenModal = () => {
    setSelectedLocation(null); // Reset selected location after edit
    setIsAddLocationModalOpen(true);
  };

  useEffect(() => {
    dispatchForSearchParamByText("");
    dispatchSetting();
    dispatchFetchAllTags();
  }, []);
  useEffect(() => {
    if (settingResponse.linkedLocations) {
      const data = getLocationToLinkResponseHelper(
        settingResponse.linkedLocations
      );
      setDynamicComponents(data);
    }
    if (
      settingResponse.locations != null &&
      settingResponse.locations != undefined &&
      settingResponse.locations?.length != 0
    ) {
      setLocations(settingResponse.locations);
    }
    if (settingResponse.settings != null) {
      setGestionePagamentiNIT(settingResponse.settings.accountabilityNIT);
      setGestionePagamentiIGN(settingResponse.settings.accountabilityIGN);
      setGestionePagamentiESV(settingResponse.settings.accountabilityESV);
      setGestionePagamentiATO(settingResponse.settings.accountabilityATO);
      if (
        settingResponse.settings?.hiddenLocation != null &&
        settingResponse.settings?.hiddenLocation != undefined
      ) {
        SetHiddenLocation(settingResponse?.settings?.hiddenLocation);
      }
    }
    if (
      settingResponse?.settings?.paymentInfo != null &&
      settingResponse?.settings?.paymentInfo != undefined
    ) {
      SetPaymentInfo(settingResponse.settings.paymentInfo);
    }
  }, [settingResponse]);

  const handlePaymentToggle = (key) => {
    SetPaymentInfo((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  useMemo(() => {
    if (tagsResponse != null && tagsResponse.length > 0) {
      const filterSearchTags = [];
      const structureSearchTags = [];
      let newHighlightedTags = new Set();
      tagsResponse.forEach((tag) => {
        if (tag.tagType == "SERVICE") {
          structureSearchTags.push(tag);
          //setStructureTags((prevTags) => [...prevTags, tag]);
        } else if (tag.tagType == "HIGHLIGHTED") {
          // setHighlightedTags((prevTags) => [...prevTags, tag]);
          newHighlightedTags.add(tag);
        } else if (tag.tagType == "FILTER") {
          filterSearchTags.push(tag);
        }
      });

      // Update highlightedTags without duplicates
      setHighlightedTags([...newHighlightedTags]);

      //FilterSearch
      const newData = [...filterSearchTags];
      const groupedData = newData.reduce((acc, obj) => {
        const key = obj.groupTitle || "No Group";
        const group = acc.find((item) => item.groupTitle === key);
        if (group) {
          group.items.push(obj);
        } else {
          acc.push({ groupTitle: key, items: [obj] });
        }
        return acc;
      }, []);
      SetFilterSearch(groupedData);

      //Structure SearchTags
      const newStructureData = [...structureSearchTags];
      const StructureData = newStructureData.reduce((acc, obj) => {
        const key = obj.groupTitle || "No Group";
        const group = acc.find((item) => item.groupTitle === key);
        if (group) {
          group.items.push(obj);
        } else {
          acc.push({ groupTitle: key, items: [obj] });
        }
        return acc;
      }, []);
      setStructureTags(StructureData);
    }
  }, [tagsResponse]);

  const handleAccountability = (option, type) => {
    if (type == "GestionePagamentiNIT") {
      const updatedOptions = gestionePagamentiNIT?.map((item) => {
        if (item.type === option.type) {
          return handleValueChecker(item, type);
        }
        return item;
      });
      setGestionePagamentiNIT(updatedOptions);
    }
    if (type == "GestionePagamentiIGN") {
      const updatedOptions = gestionePagamentiIGN?.map((item) => {
        if (item.type === option.type) {
          return handleValueChecker(item, type);
          // return { ...item, isActive: !item.isActive, accountabilityType:type };
        }
        return item;
      });
      setGestionePagamentiIGN(updatedOptions);
    }
    if (type == "GestionePagamentiESV") {
      const updatedOptions = gestionePagamentiESV?.map((item) => {
        if (item.type === option.type) {
          // Toggle the isActive value
          return handleValueChecker(item, type);
          // return { ...item, isActive: !item.isActive, accountabilityType:type };
        }
        return item;
      });
      setGestionePagamentiESV(updatedOptions);
    }
    if (type == "GestionePagamentiATO") {
      const updatedOptions = gestionePagamentiATO?.map((item) => {
        if (item.type === option.type) {
          // Toggle the isActive value
          return handleValueChecker(item, type);
          // return { ...item, isActive: !item.isActive, accountabilityType:type };
        }
        return item;
      });
      setGestionePagamentiATO(updatedOptions);
    }
  };

  const handleValueChecker = (item, type) => {
    const newIsActive = !item.isActive;
    // Toggle the isActive value
    return {
      ...item,
      isActive: newIsActive,
      accountabilityType: type,
      dataBy: newIsActive ? item.dataBy : "",
      price: newIsActive ? item.price : 0,
      priceType: newIsActive ? item.priceType : "",
      selectedDays: newIsActive ? item.selectedDays : 0,
      selectedDate: newIsActive ? item.selectedDate : null,
    };
  };

  const handlePriceTypeChange = (option, value, type) => {
    if (type == "GestionePagamentiNIT") {
      const updatedOptions = gestionePagamentiNIT?.map((item) => {
        if (item.type === option.type) {
          // Toggle the isActive value
          return { ...item, priceType: value };
        }
        return item;
      });
      setGestionePagamentiNIT(updatedOptions);
    }
    if (type == "GestionePagamentiIGN") {
      const updatedOptions = gestionePagamentiIGN?.map((item) => {
        if (item.type === option.type) {
          // Toggle the isActive value
          return { ...item, priceType: value };
        }
        return item;
      });
      setGestionePagamentiIGN(updatedOptions);
    }
    if (type == "GestionePagamentiESV") {
      const updatedOptions = gestionePagamentiESV?.map((item) => {
        if (item.type === option.type) {
          // Toggle the isActive value
          return { ...item, priceType: value };
        }
        return item;
      });
      setGestionePagamentiESV(updatedOptions);
    }
    if (type == "GestionePagamentiATO") {
      const updatedOptions = gestionePagamentiATO?.map((item) => {
        if (item.type === option.type) {
          // Toggle the isActive value
          return { ...item, priceType: value };
        }
        return item;
      });
      setGestionePagamentiATO(updatedOptions);
    }
  };

  const handlePriceValueChange = (option, value, type) => {
    // Check if priceType is '%' and validate the value
    if (option.priceType === "%" && (value < 0 || value > 100)) {
      // Display an error message or handle the invalid value as needed
      console.error("Invalid value for percentage.");
      return;
    }

    if (type == "GestionePagamentiNIT") {
      const updatedOptions = gestionePagamentiNIT?.map((item) => {
        if (item.type === option.type) {
          return { ...item, price: value };
        }
        return item;
      });
      setGestionePagamentiNIT(updatedOptions);
    }
    if (type == "GestionePagamentiIGN") {
      const updatedOptions = gestionePagamentiIGN?.map((item) => {
        if (item.type === option.type) {
          return { ...item, price: value };
        }
        return item;
      });
      setGestionePagamentiIGN(updatedOptions);
    }
    if (type == "GestionePagamentiESV") {
      const updatedOptions = gestionePagamentiESV?.map((item) => {
        if (item.type === option.type) {
          return { ...item, price: value };
        }
        return item;
      });
      setGestionePagamentiESV(updatedOptions);
    }
    if (type == "GestionePagamentiATO") {
      const updatedOptions = gestionePagamentiATO?.map((item) => {
        if (item.type === option.type) {
          return { ...item, price: value };
        }
        return item;
      });
      setGestionePagamentiATO(updatedOptions);
    }
  };

  const handleAddDynamicComponent = () => {
    setDynamicComponents([
      ...dynamicComponents,
      { selectedOption: "", selectedTagOption: [] },
    ]);
  };

  const globalSelectedOptions = dynamicComponents
    .map((component) => component.selectedOption)
    .filter((option) => option !== "");

  const handleAddTransportDynamicComponent = () => {
    setTransportDynamicComponents([
      ...transportDynamicComponents,
      { selectedOption: "", selectedTagOption: [], tags: [] },
    ]);
  };

  const handleDataByChange = (option, value, type) => {
    if (type == "GestionePagamentiNIT") {
      const updatedOptions = gestionePagamentiNIT?.map((item) => {
        if (item.type === option.type) {
          return { ...item, dataBy: value };
        }
        return item;
      });
      setGestionePagamentiNIT(updatedOptions);
    }
    if (type == "GestionePagamentiIGN") {
      const updatedOptions = gestionePagamentiIGN?.map((item) => {
        if (item.type === option.type) {
          return { ...item, dataBy: value };
        }
        return item;
      });
      setGestionePagamentiIGN(updatedOptions);
    }
    if (type == "GestionePagamentiESV") {
      const updatedOptions = gestionePagamentiESV?.map((item) => {
        if (item.type === option.type) {
          return { ...item, dataBy: value };
        }
        return item;
      });
      setGestionePagamentiESV(updatedOptions);
    }
    if (type == "GestionePagamentiATO") {
      const updatedOptions = gestionePagamentiATO?.map((item) => {
        if (item.type === option.type) {
          return { ...item, dataBy: value };
        }
        return item;
      });
      setGestionePagamentiATO(updatedOptions);
    }
  };

  const handleRemoveDynamicComponent = (index) => {
    if (dynamicComponents.length > 0) {
      setDynamicComponents(dynamicComponents.filter((_, i) => i !== index));
    }
  };

  const handleSelectedDateChange = (option, value, type) => {
    if (type == "GestionePagamentiNIT") {
      const updatedOptions = gestionePagamentiNIT?.map((item) => {
        if (item.type === option.type) {
          return { ...item, selectedDate: value };
        }
        return item;
      });
      setGestionePagamentiNIT(updatedOptions);
    }
    if (type == "GestionePagamentiIGN") {
      const updatedOptions = gestionePagamentiIGN?.map((item) => {
        if (item.type === option.type) {
          return { ...item, selectedDate: value };
        }
        return item;
      });
      setGestionePagamentiIGN(updatedOptions);
    }
    if (type == "GestionePagamentiESV") {
      const updatedOptions = gestionePagamentiESV?.map((item) => {
        if (item.type === option.type) {
          return { ...item, selectedDate: value };
        }
        return item;
      });
      setGestionePagamentiESV(updatedOptions);
    }
    if (type == "GestionePagamentiATO") {
      const updatedOptions = gestionePagamentiATO?.map((item) => {
        if (item.type === option.type) {
          return { ...item, selectedDate: value };
        }
        return item;
      });
      setGestionePagamentiATO(updatedOptions);
    }
  };

  const handleSelectedDaysChange = (option, value, type) => {
    if (type == "GestionePagamentiNIT") {
      const updatedOptions = gestionePagamentiNIT?.map((item) => {
        if (item.type === option.type) {
          return { ...item, selectedDays: value };
        }
        return item;
      });
      setGestionePagamentiNIT(updatedOptions);
    }
    if (type == "GestionePagamentiIGN") {
      const updatedOptions = gestionePagamentiIGN?.map((item) => {
        if (item.type === option.type) {
          return { ...item, selectedDays: value };
        }
        return item;
      });
      setGestionePagamentiIGN(updatedOptions);
    }
    if (type == "GestionePagamentiESV") {
      const updatedOptions = gestionePagamentiESV?.map((item) => {
        if (item.type === option.type) {
          return { ...item, selectedDays: value };
        }
        return item;
      });
      setGestionePagamentiESV(updatedOptions);
    }
    if (type == "GestionePagamentiATO") {
      const updatedOptions = gestionePagamentiATO?.map((item) => {
        if (item.type === option.type) {
          return { ...item, selectedDays: value };
        }
        return item;
      });
      setGestionePagamentiATO(updatedOptions);
    }
  };

  const handleRemoveTransportDynamicComponent = (index) => {
    if (transportDynamicComponents.length > 1) {
      setTransportDynamicComponents(
        transportDynamicComponents.filter((_, i) => i !== index)
      );
    }
  };

  const handleFilterRemove = React.useCallback(
    (index, updateFilterSearch) => {
      if (updateFilterSearch.length > 1) {
        if (updateFilterSearch[index].groupTitle === "") {
          SetFilterSearch((prevFilterSearch) =>
            prevFilterSearch.filter((_, i) => i !== index)
          );
        }
      }
    },
    [SetFilterSearch]
  );

  const handleStructureTagsRemove = React.useCallback(
    (index, updateStructureSearch) => {
      if (updateStructureSearch.length > 1) {
        if (updateStructureSearch[index].groupTitle === "") {
          setStructureTags((prevStructureSearch) =>
            prevStructureSearch.filter((_, i) => i !== index)
          );
        }
      }
    },
    [setStructureTags]
  );

  // const handleFilterChange = React.useCallback(
  //   (event, index) => {
  //     const updateFilterSearch = [...FilterSearch];
  //     updateFilterSearch[index] = {
  //       ...updateFilterSearch[index],
  //       [event.target.name]: event.target.value,
  //     };
  //     SetFilterSearch(updateFilterSearch);
  //     handleFilterRemove(index, updateFilterSearch);
  //   },
  //   [FilterSearch, SetFilterSearch, handleFilterRemove]
  // );

  const handleFilterChange = (event, index) => {
    const updateFilterSearch = [...FilterSearch];
      updateFilterSearch[index] = {
        ...updateFilterSearch[index],
        [event.target.name]: event.target.value,
      };
      SetFilterSearch(updateFilterSearch);
      handleFilterRemove(index, updateFilterSearch);
  };

  const handleFilterChangeItems = (items, index) => {
    const updateFilterSearch = [...FilterSearch];
    updateFilterSearch[index].items = items;
    SetFilterSearch(updateFilterSearch);
    handleFilterRemove(index, updateFilterSearch);
  };

  const handleStructureTagChange = React.useCallback(
    (event, index) => {
      const updateStructureSearch = [...structureTags];
      updateStructureSearch[index] = {
        ...updateStructureSearch[index],
        [event.target.name]: event.target.value,
      };
      setStructureTags(updateStructureSearch);
      handleStructureTagsRemove(index, updateStructureSearch);
    },
    [structureTags, setStructureTags, handleStructureTagsRemove]
  );

  const addFilterSearch = () => {
    SetFilterSearch((prev) => [
      ...prev,
      {
        groupTitle: "",
        items: [],
      },
    ]);
  };

  const addStructureTags = () => {
    setStructureTags((prev) => [
      ...prev,
      {
        groupTitle: "",
        items: [],
      },
    ]);
  };

  const [isLoading, setIsLoading] = useState(false);

  const addTagService = async () => {
    // const requestBody = [];
    setIsLoading(true);
    const modifiedTags = [
      ...FilterSearch.map((group) => {
        group.items.map((gi) => (gi.groupTitle = group.groupTitle));
        return group.items;
      }).flat(),
      ...highlightedTags,
      ...structureTags
        .map((group) => {
          group.items.map((gi) => (gi.groupTitle = group.groupTitle));
          return group.items;
        })
        .flat(),
    ];
    const requestBody = [];
    modifiedTags.forEach((tag) => {
      const bodyData = {
        Id: tag.id,
        DisplayName: tag.displayName,
        Code: "Name",
        GroupTitle: tag.groupTitle,
        TagType: tag.tagType,
        Status: tag.status,
        Icon: tag.icon,
      };
      requestBody.push(bodyData);
    });
    const linkedLocations = [];
    dynamicComponents.forEach((item) => {
      const payload = {
        paramId: item?.selectedOption,
        linkedIds: item?.selectedTagOption,
      };
      linkedLocations.push(payload);
    });
    const settings = {
      accountabilityNIT: gestionePagamentiNIT,
      accountabilityIGN: gestionePagamentiIGN,
      accountabilityESV: gestionePagamentiESV,
      accountabilityATO: gestionePagamentiATO,
      paymentInfo: paymentInfo,
      hiddenLocation: hiddenLocation,
    };
    let locationsBody = locations;

    await callAddTagAPI(requestBody, linkedLocations, settings, locationsBody)
      .then((res) => {
        setGestionePagamentiNIT(initialState);
        setGestionePagamentiIGN(initialState);
        setGestionePagamentiESV(initialState);
        setGestionePagamentiATO(initialState);
        toast.success("Le modifiche sono state salvate");
        navigate(`/hotel`);
      })
      .catch((e) => {
        toast.error("Error while add data!!");
        console.log("Error while add data!!");
      });
    setIsLoading(false);
  };

  const getOptionText = (type) => {
    if (type === "PREACCONTO" || type === "ACCONTO") {
      return "Entro X Giorni dalla Prenotazione";
    } else if (type === "SALDO") {
      return "Entro X Giorni dal Check In";
    } else {
      return "Entro X Giorni";
    }
  };

  const [isSidebarOpened, setIsSidebarOpened] = useState(false);

  // Function to handle the modal submit click
  const submitLocationClick = (data) => {
    if (selectedLocation) {
      // Edit the existing location
      setLocations(
        locations.map(loc =>
          loc.name === selectedLocation.name ? data : loc
        )
      );
    } else {
      // Add new location
      setLocations([...locations, data]);
    }
    setIsAddLocationModalOpen(false);
    setSelectedLocation(null); // Reset selected location after edit
  };

  // Function to handle deletion of a location
  const handleDeleteLocation = (row) => {
    const locationName = row.data.name; // Assuming the location name is stored in 'name'

  // Show a confirmation alert
  const userConfirmed = window.confirm(`Vuoi rimuovere la località ${locationName}?`);

  // Proceed with deletion if the user confirmed
  if (userConfirmed) {
    setLocations((prevLocations) =>
      prevLocations.filter((location) => location.id !== row.data.id)
    );
  }
  };

  // Function to handle editing of a location (can be customized based on your requirements)
  const handleEditLocation = (row) => {
    setIsAddLocationModalOpen(true); // Open the modal
    setSelectedLocation(row.data); // Set the selected data for editing
    // You can implement the edit logic here (for example, open a modal for editing)
  };

  const columns = [
    {
      field: "name",
      headerName: "Name Localita",
      minWidth: 150,
    },
    {
      field: "region",
      headerName: "Region",
      minWidth: 150,
    },
    {
      headerName: "AvesLocations",
      minWidth: 200,
      valueGetter: (params) =>
        params?.data?.avesLocations ? params?.data?.avesLocations?.length : 0,
    }
  ];

  const Custom_Separator = () => (
    <Typography variant="body1" style={{ fontSize: "20px", color: "#26529c" }}>
      ›
    </Typography>
  );

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/hotel"
      sx={{
        color: "#26529c",
        fontSize: "16px",
        fontWeight: "600",
        "&:hover": { color: "#26529c", fontSize: "16px", fontWeight: "600" },
      }}
    >
      Hotel
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/settings"
      sx={{
        color: "#26529c",
        fontSize: "16px",
        fontWeight: "600",
        "&:hover": { color: "#26529c", fontSize: "16px", fontWeight: "600" },
      }}
    >
      Impostazioni
    </Link>,
  ];

  const handleToggle = () => {
    setIsSidebarOpened(!isSidebarOpened);
  };
  return (
    <Layout handleToggle={handleToggle} isSidebarOpened={isSidebarOpened}>
      <Box sx={{ height: "48px", padding: "10px" }}>
        <Stack spacing={2} sx={{ marginLeft: "20px" }}>
          <Breadcrumbs separator={<Custom_Separator />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
      </Box>
      <Box
        sx={{
          backgroundColor: "#f2f2f2",
          minHeight: "90vh",
          padding: "40px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            marginTop: "30px",
            backgroundColor: "white",
            borderRadius: "10px",
            minHeight: "237px",
            padding: "15px 20px",
            display: "flex",
            flexDirection: isSidebarOpened ? "column" : "row",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography sx={{ fontSize: "24px", fontWeight: "500px" }}>
              Località
            </Typography>
            <Typography
              sx={{
                width: "313px",
                fontSize: "15px",
                fontWeight: "400",
                color: "#C7C7C7",
              }}
            >
              Questa funzionalità collega una località principale a delle
              secondarie, e nel sito web verrà mostrata la ricerca collegata,
              ricorda che non vale il viceversa.
            </Typography>
          </Box>
          <Box
            sx={{
              margin: "10px 0px 10px 30px",
              width: "876px",
              display: "flex",
              alignItems: "center",
              borderRadius: "10px",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                minWidth: "100%",
                overflowX: "auto",
              }}
            >
              <TableApp rows={locations} columns={columns} pagination={true} onEdit={handleEditLocation} onDelete={handleDeleteLocation} />
            </Box>
            <Button
              variant="outlined"
              fullWidth
              onClick={handleLoactionOpenModal}
              sx={{
                marginTop: "5px",
              }}
            >
              <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
                Aggiungi Nuova
              </Typography>
            </Button>

            <AddLocationModel
              isOpen={isAddLocationModalOpen}
              onClose={handleLoactionCloseModal}
              submitClick={submitLocationClick}
              initialData={selectedLocation} // Pass the selected location data for editing
            />
          </Box>
        </Box>

        {/* <Box
          sx={{
            marginTop: "30px",
            backgroundColor: "white",
            borderRadius: "10px",
            minHeight: "196px",
            padding: "15px 20px",
            display: "flex",
            flexDirection: isSidebarOpened ? "column" : "row",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography sx={{ fontSize: "24px", fontWeight: "500px" }}>
              Transport Group
            </Typography>
            <Typography
              sx={{
                width: "313px",
                fontSize: "15px",
                fontWeight: "400",
                color: "#C7C7C7",
              }}
            >
              Remember that the hierarchy is sorted by Region, Province, Hotel
              (the most important)
            </Typography>
          </Box>
          <Box>
            {transportDynamicComponents.map((component, index) => (
              <Box
                key={index}
                sx={{
                  marginTop: "30px",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  minHeight: "137px",
                  padding: "15px 20px 0px 20px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <DropdownBox
                  selectedOption={component.selectedOption}
                  setSelectedOption={(value) => {
                    const updatedComponents = [...transportDynamicComponents];
                    updatedComponents[index].selectedOption = value;
                    setTransportDynamicComponents(updatedComponents);
                  }}
                  tags={component.tags}
                  setTags={(value) => {
                    const updatedComponents = [...transportDynamicComponents];
                    updatedComponents[index].tags = value;
                    setTransportDynamicComponents(updatedComponents);
                  }}
                  removeBox={() => handleRemoveTransportDynamicComponent(index)}
                />
              </Box>
            ))}
            <Button
              sx={{
                margin: "10px 0px 10px 50px",
                width: "220px",
                height: "31px",
                border: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
                textTransform: "none",
                color: "black",
              }}
              onClick={handleAddTransportDynamicComponent}
            >
              <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
                Aggiungi Categoria Filtro
              </Typography>
              <img
                src={addIcon}
                alt="icon"
                style={{ marginLeft: "5px", width: "16px", height: "16px" }}
              />
            </Button>
          </Box>
        </Box> */}

        <Box
          sx={{
            marginTop: "30px",
            backgroundColor: "white",
            borderRadius: "10px",
            minHeight: "487px",
            padding: "15px 20px",
            display: "flex",
            flexDirection: isSidebarOpened ? "column" : "row",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography sx={{ fontSize: "24px", fontWeight: "500px" }}>
              Filtri Motore di Ricerca
            </Typography>
            <Typography
              sx={{
                width: "313px",
                fontSize: "15px",
                fontWeight: "400",
                color: "#C7C7C7",
              }}
            >
              Questi tags se applicati nei relativi hotel verranno mostrati
              nella sezione dei filtri a sinistra, presente nella pagina dei
              risultati.
            </Typography>
          </Box>
          <Box
            sx={{
              margin: "10px 0px 10px 30px",
              width: "876px",
              display: "flex",
              alignItems: "center",
              borderRadius: "10px",
              flexWrap: "wrap",
            }}
          >
            {FilterSearch?.map((item, index) => {
              return (
                <FilterSearchEngine
                  key={index}
                  item={item}
                  index={index}
                  handleFilterChange={handleFilterChange}
                  handleFilterChangeItems={handleFilterChangeItems}
                  type="FILTER"
                />
              );
            })}

            <Button
              sx={{
                margin: "20px 0px 10px 0px",
                width: "220px",
                height: "31px",
                border: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
                textTransform: "none",
                color: "black",
              }}
              onClick={addFilterSearch}
            >
              <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
                Aggiungi Categoria Filtro
              </Typography>
              <img
                src={addIcon}
                alt="icon"
                style={{ marginLeft: "5px", width: "16px", height: "16px" }}
              />
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            marginTop: "30px",
            backgroundColor: "white",
            borderRadius: "10px",
            minHeight: "178px",
            padding: "15px 20px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography sx={{ fontSize: "24px", fontWeight: "500px" }}>
              Servizi in Evidenza
            </Typography>
            <Typography
              sx={{
                width: "313px",
                fontSize: "15px",
                fontWeight: "400",
                color: "#C7C7C7",
              }}
            >
              Questi tags se applicati nei relativi hotel verranno mostrati nei
              servizi della struttura nella pagina hotel.
            </Typography>
          </Box>
          <Box
            sx={{
              margin: "10px 0px 10px 30px",
              width: "876px",
              display: "flex",
              alignItems: "center",
              borderRadius: "10px",
              flexWrap: "wrap",
            }}
          >
            <CheckBoxGroupWithIcon
              label={label}
              items={highlightedTags}
              type="HIGHLIGHTED"
              groupTitle=""
            />
          </Box>
        </Box>

        <Box
          sx={{
            marginTop: "30px",
            backgroundColor: "white",
            borderRadius: "10px",
            minHeight: "178px",
            padding: "15px 20px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography sx={{ fontSize: "24px", fontWeight: "500px" }}>
              Servizi Struttura
            </Typography>
            <Typography
              sx={{
                width: "313px",
                fontSize: "15px",
                fontWeight: "400",
                color: "#C7C7C7",
              }}
            >
              Questi tags se applicati nei relativi hotel verranno mostrati nei
              primi 6 servizi in evidenza nella pagina hotel.
            </Typography>
          </Box>
          <Box
            sx={{
              margin: "10px 0px 10px 30px",
              width: "876px",
              display: "flex",
              alignItems: "center",
              borderRadius: "10px",
              flexWrap: "wrap",
            }}
          >
            {structureTags?.map((item, index) => {
              return (
                <FilterSearchEngine
                  key={index}
                  item={item}
                  index={index}
                  handleFilterChange={handleStructureTagChange}
                  type="SERVICE"
                />
              );
            })}

            <Button
              sx={{
                margin: "20px 0px 10px 0px",
                width: "220px",
                height: "31px",
                border: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
                textTransform: "none",
                color: "black",
              }}
              onClick={addStructureTags}
            >
              <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
                Aggiungi Categoria Filtro
              </Typography>
              <img
                src={addIcon}
                alt="icon"
                style={{ marginLeft: "5px", width: "16px", height: "16px" }}
              />
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            marginTop: "30px",
            backgroundColor: "white",
            borderRadius: "10px",
            minHeight: "105px",
            padding: "15px 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
                  Metodi di Pagamento Abilitati
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    paddingTop: "6px",
                    marginLeft: "10px",
                  }}
                ></Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    width: "313px",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#C7C7C7",
                  }}
                >
                  Verificare che non ci siano mismatch con i ricavi degli hotel
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { xs: "auto", sm: "876px" },
                justifyContent: "space-between",
                alignItems: "start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <Button
                  key="IsCarta"
                  variant="outlined"
                  sx={{
                    marginRight: "10px",
                    height: "30px",
                    width: "100%",
                    fontSize: "16px",
                    fontWeight: "400",
                    textTransform: "none",
                    marginTop: { xs: "4px", md: "4px" },

                    ...(paymentInfo.isCarta == true && {
                      color: "#4167a8",
                      borderColor: "#4167a8",
                    }),
                    ...(paymentInfo.isCarta == false && {
                      color: "#b3b3b3",
                      borderColor: "#b3b3b3",
                    }),
                  }}
                  onClick={() => handlePaymentToggle("isCarta")}
                >
                  Carta
                </Button>

                <Button
                  key="IsBankTransfer"
                  variant="outlined"
                  sx={{
                    marginRight: "10px",
                    height: "30px",
                    width: "100%",
                    fontSize: "16px",
                    fontWeight: "400",
                    textTransform: "none",
                    marginTop: { xs: "4px", md: "4px" },

                    ...(paymentInfo.isBankTransfer == true && {
                      color: "#4167a8",
                      borderColor: "#4167a8",
                    }),
                    ...(paymentInfo.isBankTransfer == false && {
                      color: "#b3b3b3",
                      borderColor: "#b3b3b3",
                    }),
                  }}
                  onClick={() => handlePaymentToggle("isBankTransfer")}
                >
                  Bonifico
                </Button>

                <Button
                  key="IsScalapay"
                  variant="outlined"
                  sx={{
                    marginRight: "10px",
                    height: "30px",
                    width: "100%",
                    fontSize: "16px",
                    fontWeight: "400",
                    textTransform: "none",
                    marginTop: { xs: "4px", md: "4px" },

                    ...(paymentInfo.isScalapay == true && {
                      color: "#4167a8",
                      borderColor: "#4167a8",
                    }),
                    ...(paymentInfo.isScalapay == false && {
                      color: "#b3b3b3",
                      borderColor: "#b3b3b3",
                    }),
                  }}
                  onClick={() => handlePaymentToggle("isScalapay")}
                >
                  ScalaPay
                </Button>

                <Button
                  key="IsKlarna"
                  variant="outlined"
                  sx={{
                    marginRight: "10px",
                    height: "30px",
                    width: "100%",
                    fontSize: "16px",
                    fontWeight: "400",
                    textTransform: "none",
                    marginTop: { xs: "4px", md: "4px" },

                    ...(paymentInfo.isKlarna == true && {
                      color: "#4167a8",
                      borderColor: "#4167a8",
                    }),
                    ...(paymentInfo.isKlarna == false && {
                      color: "#b3b3b3",
                      borderColor: "#b3b3b3",
                    }),
                  }}
                  onClick={() => handlePaymentToggle("isKlarna")}
                >
                  Klarna
                </Button>
              </Box>

              <Box></Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            marginTop: "30px",
            backgroundColor: "white",
            borderRadius: "10px",
            minHeight: "105px",
            padding: "15px 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
                  Gestione Pagamenti NIT
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    paddingTop: "6px",
                    marginLeft: "10px",
                  }}
                ></Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    width: "313px",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#C7C7C7",
                  }}
                >
                  Qui si può inserire la logica dei pagamenti delle vacanze per
                  questo Hotel. Se questa sezione rimane vuota il pagamento
                  della vacanza verrà saldato tutto subito.
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { xs: "auto", sm: "876px" },
                justifyContent: "space-between",
                alignItems: "start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                }}
              >
                {gestionePagamentiNIT?.map((option, index) => (
                  <Button
                    key={index}
                    variant="outlined"
                    sx={{
                      marginRight: "10px",
                      height: "30px",
                      width: "100%",
                      fontSize: "16px",
                      fontWeight: "400",
                      textTransform: "none",
                      marginTop: { xs: "4px", md: "4px" },

                      ...(option.isActive == true && {
                        color: "#4167a8",
                        borderColor: "#4167a8",
                      }),
                      ...(option.isActive == false && {
                        color: "#b3b3b3",
                        borderColor: "#b3b3b3",
                      }),
                    }}
                    onClick={() =>
                      handleAccountability(option, "GestionePagamentiNIT")
                    }
                  >
                    {option.title}
                  </Button>
                ))}
              </Box>

              {gestionePagamentiNIT.map(
                (option) =>
                  option.isActive && (
                    <Box key={option.value} sx={{ marginTop: "20px" }}>
                      <Typography sx={{ fontSize: "15px", fontWeight: "500" }}>
                        {option.title}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          width: { xs: "auto", sm: "876px" },
                        }}
                      >
                        <Select
                          sx={{ width: "15%" }}
                          value={option.priceType}
                          onChange={(e) =>
                            handlePriceTypeChange(
                              option,
                              e.target.value,
                              "GestionePagamentiNIT"
                            )
                          }
                        >
                          <MenuItem value={"€"}>€</MenuItem>
                          <MenuItem value={"%"}>%</MenuItem>
                        </Select>
                        <TextField
                          sx={{
                            borderRadius: "5px",
                          }}
                          type="number"
                          variant="outlined"
                          placeholder="Number"
                          value={option.price}
                          onChange={(e) =>
                            handlePriceValueChange(
                              option,
                              e.target.value,
                              "GestionePagamentiNIT"
                            )
                          }
                        />
                        {option.type !== "SCONTOUNICO" && (
                          <>
                            <Select
                              sx={{ width: "100%", margin: "0px 10px" }}
                              value={option.dataBy}
                              onChange={(e) =>
                                handleDataByChange(
                                  option,
                                  e.target.value,
                                  "GestionePagamentiNIT"
                                )
                              }
                            >
                              <MenuItem value={"DATE"}>Entro la Data</MenuItem>
                              {/* <MenuItem value={"DAYS"}>{option.type === "PREACCONTO" || option.type === "ACCONTO" ? "Entro X Giorni dalla Prenotazione" ? option.type === "SALDO" : "Entro X Giorni dal Check In" :"Entro X Giorni" }</MenuItem> */}
                              <MenuItem value={"DAYS"}>
                                {getOptionText(option.type)}
                              </MenuItem>
                            </Select>
                            {option.dataBy === "DATE" ? (
                              <TextField
                                sx={{
                                  borderRadius: "5px",
                                  width: "300px",
                                }}
                                type="date"
                                variant="outlined"
                                placeholder="date"
                                value={
                                  option.selectedDate != null &&
                                  format(
                                    new Date(option.selectedDate),
                                    "yyyy-MM-dd"
                                  )
                                }
                                onChange={(e) =>
                                  handleSelectedDateChange(
                                    option,
                                    e.target.value,
                                    "GestionePagamentiNIT"
                                  )
                                }
                              />
                            ) : (
                              <TextField
                                sx={{
                                  borderRadius: "5px",
                                  width: "300px",
                                }}
                                type="number"
                                variant="outlined"
                                placeholder="Giorni"
                                value={option.selectedDays}
                                onChange={(e) =>
                                  handleSelectedDaysChange(
                                    option,
                                    e.target.value,
                                    "GestionePagamentiNIT"
                                  )
                                }
                              />
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                  )
              )}

              <Box></Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            marginTop: "30px",
            backgroundColor: "white",
            borderRadius: "10px",
            minHeight: "105px",
            padding: "15px 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
                  Gestione Pagamenti IGN
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    paddingTop: "6px",
                    marginLeft: "10px",
                  }}
                ></Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    width: "313px",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#C7C7C7",
                  }}
                >
                  Qui si può inserire la logica dei pagamenti delle vacanze per
                  questo Hotel. Se questa sezione rimane vuota il pagamento
                  della vacanza verrà saldato tutto subito.
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { xs: "auto", sm: "876px" },
                justifyContent: "space-between",
                alignItems: "start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                }}
              >
                {gestionePagamentiIGN?.map((option, index) => (
                  <Button
                    key={index}
                    variant="outlined"
                    sx={{
                      marginRight: "10px",
                      height: "30px",
                      width: "100%",
                      fontSize: "16px",
                      fontWeight: "400",
                      textTransform: "none",
                      marginTop: { xs: "4px", md: "4px" },

                      ...(option.isActive == true && {
                        color: "#4167a8",
                        borderColor: "#4167a8",
                      }),
                      ...(option.isActive == false && {
                        color: "#b3b3b3",
                        borderColor: "#b3b3b3",
                      }),
                    }}
                    onClick={() =>
                      handleAccountability(option, "GestionePagamentiIGN")
                    }
                  >
                    {option.title}
                  </Button>
                ))}
              </Box>
              {gestionePagamentiIGN?.map(
                (option) =>
                  option.isActive && (
                    <Box key={option.value} sx={{ marginTop: "20px" }}>
                      <Typography sx={{ fontSize: "15px", fontWeight: "500" }}>
                        {option.title}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          width: { xs: "auto", sm: "876px" },
                        }}
                      >
                        <Select
                          sx={{ width: "15%" }}
                          value={option.priceType}
                          onChange={(e) =>
                            handlePriceTypeChange(
                              option,
                              e.target.value,
                              "GestionePagamentiIGN"
                            )
                          }
                        >
                          <MenuItem value={"€"}>€</MenuItem>
                          <MenuItem value={"%"}>%</MenuItem>
                        </Select>
                        <TextField
                          sx={{
                            borderRadius: "5px",
                          }}
                          type="number"
                          variant="outlined"
                          placeholder="Number"
                          value={option.price}
                          onChange={(e) =>
                            handlePriceValueChange(
                              option,
                              e.target.value,
                              "GestionePagamentiIGN"
                            )
                          }
                        />
                        {option.type !== "SCONTOUNICO" && (
                          <>
                            <Select
                              sx={{ width: "100%", margin: "0px 10px" }}
                              value={option.dataBy}
                              onChange={(e) =>
                                handleDataByChange(
                                  option,
                                  e.target.value,
                                  "GestionePagamentiIGN"
                                )
                              }
                            >
                              <MenuItem value={"DATE"}>Entro la Data</MenuItem>
                              <MenuItem value={"DAYS"}>
                                {getOptionText(option.type)}
                              </MenuItem>
                            </Select>
                            {option.dataBy === "DATE" ? (
                              <TextField
                                sx={{
                                  borderRadius: "5px",
                                  width: "300px",
                                }}
                                type="date"
                                variant="outlined"
                                placeholder="date"
                                value={
                                  option.selectedDate != null &&
                                  format(
                                    new Date(option.selectedDate),
                                    "yyyy-MM-dd"
                                  )
                                }
                                onChange={(e) =>
                                  handleSelectedDateChange(
                                    option,
                                    e.target.value,
                                    "GestionePagamentiIGN"
                                  )
                                }
                              />
                            ) : (
                              <TextField
                                sx={{
                                  borderRadius: "5px",
                                  width: "300px",
                                }}
                                type="number"
                                variant="outlined"
                                placeholder="Giorni"
                                value={option.selectedDays}
                                onChange={(e) =>
                                  handleSelectedDaysChange(
                                    option,
                                    e.target.value,
                                    "GestionePagamentiIGN"
                                  )
                                }
                              />
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                  )
              )}
              <Box></Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "30px",
            backgroundColor: "white",
            borderRadius: "10px",
            minHeight: "105px",
            padding: "15px 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
                  Gestione Pagamenti ESV
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    paddingTop: "6px",
                    marginLeft: "10px",
                  }}
                ></Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    width: "313px",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#C7C7C7",
                  }}
                >
                  Qui si può inserire la logica dei pagamenti delle vacanze per
                  questo Hotel. Se questa sezione rimane vuota il pagamento
                  della vacanza verrà saldato tutto subito.
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { xs: "auto", sm: "876px" },
                justifyContent: "space-between",
                alignItems: "start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                }}
              >
                {gestionePagamentiESV?.map((option, index) => (
                  <Button
                    key={index}
                    variant="outlined"
                    sx={{
                      marginRight: "10px",
                      height: "30px",
                      width: "100%",
                      fontSize: "16px",
                      fontWeight: "400",
                      textTransform: "none",
                      marginTop: { xs: "4px", md: "4px" },

                      ...(option.isActive == true && {
                        color: "#4167a8",
                        borderColor: "#4167a8",
                      }),
                      ...(option.isActive == false && {
                        color: "#b3b3b3",
                        borderColor: "#b3b3b3",
                      }),
                    }}
                    onClick={() =>
                      handleAccountability(option, "GestionePagamentiESV")
                    }
                  >
                    {option.title}
                  </Button>
                ))}
              </Box>

              {gestionePagamentiESV?.map(
                (option) =>
                  option.isActive && (
                    <Box key={option.value} sx={{ marginTop: "20px" }}>
                      <Typography sx={{ fontSize: "15px", fontWeight: "500" }}>
                        {option.title}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          width: { xs: "auto", sm: "876px" },
                        }}
                      >
                        <Select
                          sx={{ width: "15%" }}
                          value={option.priceType}
                          onChange={(e) =>
                            handlePriceTypeChange(
                              option,
                              e.target.value,
                              "GestionePagamentiESV"
                            )
                          }
                        >
                          <MenuItem value={"€"}>€</MenuItem>
                          <MenuItem value={"%"}>%</MenuItem>
                        </Select>
                        <TextField
                          sx={{
                            borderRadius: "5px",
                          }}
                          type="number"
                          variant="outlined"
                          placeholder="Number"
                          value={option.price}
                          onChange={(e) =>
                            handlePriceValueChange(
                              option,
                              e.target.value,
                              "GestionePagamentiESV"
                            )
                          }
                        />
                        {option.type !== "SCONTOUNICO" && (
                          <>
                            <Select
                              sx={{ width: "100%", margin: "0px 10px" }}
                              value={option.dataBy}
                              onChange={(e) =>
                                handleDataByChange(
                                  option,
                                  e.target.value,
                                  "GestionePagamentiESV"
                                )
                              }
                            >
                              <MenuItem value={"DATE"}>Entro la Data</MenuItem>
                              <MenuItem value={"DAYS"}>
                                {getOptionText(option.type)}
                              </MenuItem>
                            </Select>
                            {option.dataBy === "DATE" ? (
                              <TextField
                                sx={{
                                  borderRadius: "5px",
                                  width: "300px",
                                }}
                                type="date"
                                variant="outlined"
                                placeholder="date"
                                value={
                                  option.selectedDate != null &&
                                  format(
                                    new Date(option.selectedDate),
                                    "yyyy-MM-dd"
                                  )
                                }
                                onChange={(e) =>
                                  handleSelectedDateChange(
                                    option,
                                    e.target.value,
                                    "GestionePagamentiESV"
                                  )
                                }
                              />
                            ) : (
                              <TextField
                                sx={{
                                  borderRadius: "5px",
                                  width: "300px",
                                }}
                                type="number"
                                variant="outlined"
                                placeholder="Giorni"
                                value={option.selectedDays}
                                onChange={(e) =>
                                  handleSelectedDaysChange(
                                    option,
                                    e.target.value,
                                    "GestionePagamentiESV"
                                  )
                                }
                              />
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                  )
              )}
              <Box></Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "30px",
            backgroundColor: "white",
            borderRadius: "10px",
            minHeight: "105px",
            padding: "15px 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
                  Gestione Pagamenti ATO
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    paddingTop: "6px",
                    marginLeft: "10px",
                  }}
                ></Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    width: "313px",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#C7C7C7",
                  }}
                >
                  Qui si può inserire la logica dei pagamenti delle vacanze per
                  questo Hotel. Se questa sezione rimane vuota il pagamento
                  della vacanza verrà saldato tutto subito.
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { xs: "auto", sm: "876px" },
                justifyContent: "space-between",
                alignItems: "start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                }}
              >
                {gestionePagamentiATO?.map((option, index) => (
                  <Button
                    key={index}
                    variant="outlined"
                    sx={{
                      marginRight: "10px",
                      height: "30px",
                      width: "100%",
                      fontSize: "16px",
                      fontWeight: "400",
                      textTransform: "none",
                      marginTop: { xs: "4px", md: "4px" },

                      ...(option.isActive == true && {
                        color: "#4167a8",
                        borderColor: "#4167a8",
                      }),
                      ...(option.isActive == false && {
                        color: "#b3b3b3",
                        borderColor: "#b3b3b3",
                      }),
                    }}
                    onClick={() =>
                      handleAccountability(option, "GestionePagamentiATO")
                    }
                  >
                    {option.title}
                  </Button>
                ))}
              </Box>

              {gestionePagamentiATO?.map(
                (option) =>
                  option.isActive && (
                    <Box key={option.value} sx={{ marginTop: "20px" }}>
                      <Typography sx={{ fontSize: "15px", fontWeight: "500" }}>
                        {option.title}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          width: { xs: "auto", sm: "876px" },
                        }}
                      >
                        <Select
                          sx={{ width: "15%" }}
                          value={option.priceType}
                          onChange={(e) =>
                            handlePriceTypeChange(
                              option,
                              e.target.value,
                              "GestionePagamentiATO"
                            )
                          }
                        >
                          <MenuItem value={"€"}>€</MenuItem>
                          <MenuItem value={"%"}>%</MenuItem>
                        </Select>
                        <TextField
                          sx={{
                            borderRadius: "5px",
                          }}
                          type="number"
                          variant="outlined"
                          placeholder="Number"
                          value={option.price}
                          onChange={(e) =>
                            handlePriceValueChange(
                              option,
                              e.target.value,
                              "GestionePagamentiATO"
                            )
                          }
                        />
                        {option.type !== "SCONTOUNICO" && (
                          <>
                            <Select
                              sx={{ width: "100%", margin: "0px 10px" }}
                              value={option.dataBy}
                              onChange={(e) =>
                                handleDataByChange(
                                  option,
                                  e.target.value,
                                  "GestionePagamentiATO"
                                )
                              }
                            >
                              <MenuItem value={"DATE"}>Entro la Data</MenuItem>
                              <MenuItem value={"DAYS"}>
                                {getOptionText(option.type)}
                              </MenuItem>
                            </Select>
                            {option.dataBy === "DATE" ? (
                              <TextField
                                sx={{
                                  borderRadius: "5px",
                                  width: "300px",
                                }}
                                type="date"
                                variant="outlined"
                                placeholder="date"
                                value={
                                  option.selectedDate != null &&
                                  format(
                                    new Date(option.selectedDate),
                                    "yyyy-MM-dd"
                                  )
                                }
                                onChange={(e) =>
                                  handleSelectedDateChange(
                                    option,
                                    e.target.value,
                                    "GestionePagamentiATO"
                                  )
                                }
                              />
                            ) : (
                              <TextField
                                sx={{
                                  borderRadius: "5px",
                                  width: "300px",
                                }}
                                type="number"
                                variant="outlined"
                                placeholder="Giorni"
                                value={option.selectedDays}
                                onChange={(e) =>
                                  handleSelectedDaysChange(
                                    option,
                                    e.target.value,
                                    "GestionePagamentiATO"
                                  )
                                }
                              />
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                  )
              )}
              <Box></Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button
            disabled={isLoading}
            variant="contained"
            sx={{ marginTop: "20px" }}
            onClick={addTagService}
          >
            {isLoading ? (
              <CircularProgress size={24} sx={{ color: "white" }} />
            ) : (
              "Salva"
            )}
          </Button>
        </Box>
      </Box>
    </Layout>
  );
}
